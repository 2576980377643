import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { amountOfNotificationsOnPage } from './consts';
import { fetchNotifications } from './store/effects';
import { $notifications } from './store/states';
import { notificationsApi } from './store/apis';
import { NotificationsWrapper, NotificationPositioner } from './styled';
import { NotificationResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import moment from 'moment';
import Flex from '@app/components/Flex';
import { Button, Divider, Icon, Intent } from '@blueprintjs/core';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import notificationFactory from './consts/notificationFactory';
import { $authorizedUser } from '@app/containers/store/states';
import GenericNotification from './components/GenericNotification';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import NonIdealState from '@app/components/NonIdealState';

const Notifications: React.FC = (props) => {
    const authorizedUser = useStore($authorizedUser);
    const notifications = useStore($notifications);
    const isFetchingNotifications = useStore(fetchNotifications.pending);

    const handleFetchNotifications = async () => {
        try {
            // Get cursor
            let cursor: undefined | ID;
            if (notifications && notifications.cursor && notifications.cursor.next_cursor_start !== null) {
                cursor = notifications.cursor.next_cursor_start;
            }

            // Fetch notifications
            const fetchedNotifications = await fetchNotifications({
                pagination: {
                    _cursor: {
                        direction: 'desc',
                        limit: amountOfNotificationsOnPage,
                        start: cursor,
                    },
                },
            });

            // Add task messages at the end
            notificationsApi.addAtTailAfterSuccessfulFetch({ notifications: fetchedNotifications });
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({ message: `Notifications were not found`, intent: Intent.DANGER });
            }
        }
    };

    useEffect(() => {
        handleFetchNotifications();
    }, []);

    useEffect(() => {
        // This happens in the situation whenever all notifications were removed, but there are more notifications to show, which should be fetched
        if (notifications && notifications.list.length === 0 && notifications.cursor && notifications.cursor.has_more) {
            handleFetchNotifications();
        }
    }, [notifications]);

    if (!authorizedUser) {
        return null;
    }

    const renderHeader = () => {
        return (
            <Flex direction="row" align="center">
                <Icon className="mr-1" icon="comparison" size={20} />
                <Heading type="h5">Notifications</Heading>
            </Flex>
        );
    };

    const renderNotificationDateHeader = (at: string) => {
        const notificationAtMoment = moment(at);

        const renderSuffix = () => {
            const commonSuffixProps = {
                inline: true,
                muted: true,
                className: 'ml-2',
            };

            const isStateTransitionDateToday = notificationAtMoment.isSame(moment(), 'day');
            if (isStateTransitionDateToday) {
                // It is today
                return <DevText {...commonSuffixProps}>Today</DevText>;
            }

            return (
                <DevText {...commonSuffixProps}>{TextFormatUtils.capitalize(notificationAtMoment.fromNow())}</DevText>
            );
        };

        return (
            <Heading className="mb-2" type="h5">
                {notificationAtMoment.format('D MMMM YYYY')}
                {renderSuffix()}
            </Heading>
        );
    };

    const renderNotifications = () => {
        if (!notifications) {
            // Notifications were NOT fetched yet
            return null;
        }

        if (notifications.list.length === 0) {
            // There are no new notifications
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No new notifications
                        </Heading>
                    }
                />
            );
        }

        const shouldRenderNotificationAtDate = (currentNotification: NotificationResource, index: number) => {
            if (index === 0) {
                return true;
            }

            // Get previous notifications
            const previousNotification = notifications.list[index - 1];

            // Get previous and current notification at dates
            const previousNotificationAt = moment(previousNotification.activity.at);
            const currentNotificationAt = moment(currentNotification.activity.at);

            if (previousNotificationAt.isSame(currentNotificationAt, 'day')) {
                // Same day, no need to show new date header for notification
                return false;
            }

            return true;
        };

        const renderNotification = (shouldRenderAtDate: boolean, notification: NotificationResource, index: number) => {
            // Get notification renderer function
            const notificationRendererFunction = notificationFactory[notification.activity.type];

            // Get flag is date rendered at least once
            const isDateRenderedAtLeastOnce = index > 0;

            return (
                <>
                    {/* Date header */}
                    {shouldRenderAtDate && isDateRenderedAtLeastOnce && <Divider className="mt-4 mb-4" />}
                    {shouldRenderAtDate && renderNotificationDateHeader(notification.activity.at)}

                    {/* Notification */}
                    {!!notificationRendererFunction ? (
                        notificationRendererFunction(notification, notifications!.entity, authorizedUser.user.id)
                    ) : (
                        <GenericNotification notification={notification} authorizedUserId={authorizedUser.user.id} />
                    )}
                </>
            );
        };

        return (
            <div>
                {notifications.list.map((notification, index) => {
                    // Get flag if the date should be rendered
                    const isDateVisible = shouldRenderNotificationAtDate(notification, index);

                    return (
                        <div className={index === 0 ? '' : 'mt-3'} key={notification.activity.id}>
                            {renderNotification(isDateVisible, notification, index)}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <NotificationsWrapper {...props}>
            <NotificationPositioner className="custom-thin-scroll">
                <div className="mb-2">{renderHeader()}</div>
                {renderNotifications()}
                {notifications && notifications.cursor && notifications.cursor.has_more && (
                    <Flex className="mt-2" justify="center" align="center">
                        <Button
                            loading={isFetchingNotifications}
                            outlined
                            icon="refresh"
                            rightIcon="refresh"
                            onClick={handleFetchNotifications}
                        >
                            Show more
                        </Button>
                    </Flex>
                )}
            </NotificationPositioner>
        </NotificationsWrapper>
    );
};

export default Notifications;
