import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { $task} from '../../store/states';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { $authorizedUser } from '@app/containers/store/states';
import Archived from '@pages/Task/components/TaskFlowControls/components/Archived';
import Draft from '@pages/Task/components/TaskFlowControls/components/Draft';
import Published from '@pages/Task/components/TaskFlowControls/components/Published';
import PickupReady from '@pages/Task/components/TaskFlowControls/components/PickupReady';
import InProgress from '@pages/Task/components/TaskFlowControls/components/InProgress';
import InternalQa from '@pages/Task/components/TaskFlowControls/components/InternalQa';
import InRevision from '@pages/Task/components/TaskFlowControls/components/InRevision';
import ClientReview from '@pages/Task/components/TaskFlowControls/components/ClientReview';
import RevisionRequired from '@pages/Task/components/TaskFlowControls/components/RevisionRequired';
import PreDelivered from '@pages/Task/components/TaskFlowControls/components/PreDelivered';
import Delivered from '@pages/Task/components/TaskFlowControls/components/Delivered';
import Paused from '@pages/Task/components/TaskFlowControls/components/Paused';

export type Props = HTMLAttributes<HTMLDivElement>;

const TaskFlowControls: React.FC<Props> = (props) => {
    const authorizedUser = useStore($authorizedUser);
    const task = useStore($task);

    if (!authorizedUser) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    // Task is archived
    if (task.archived_at !== null) {
        return <Archived />;
    }

    // Task is paused
    if (task.paused_at !== null) {
        return <Paused />;
    }

    // Render based on state
    switch (task.state) {
        case TaskState.DRAFT:
            return <Draft />;
        case TaskState.PUBLISHED:
            return <Published />;
        case TaskState.PICKUP_READY:
            return <PickupReady />;
        case TaskState.IN_PROGRESS:
            return <InProgress />;
        case TaskState.INTERNAL_QA:
            return <InternalQa />;
        case TaskState.IN_REVISION:
            return <InRevision />;
        case TaskState.CLIENT_REVIEW:
            return <ClientReview />;
        case TaskState.REVISION_REQUIRED:
            return <RevisionRequired />;
        case TaskState.PRE_DELIVERED:
            return <PreDelivered />;
        case TaskState.DELIVERED:
            return <Delivered />;
    }

    return null;
};

export default TaskFlowControls;
