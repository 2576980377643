import { taskApi } from '@app/containers/pages/Task/store/apis';
import { Button, Card } from '@blueprintjs/core';
import Flex from '@components/Flex';
import { joinTaskAsParticipantUser } from '@containers/pages/Task/store/effects';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { openModal } from '@modals/store/events';
import { $task } from '@pages/Task/store/states';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useState } from 'react';
import ParticipantListItem from './components/ParticipantListItem';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import AddTaskParticipantsModal from '@app/containers/pages/Task/modals/AddTaskParticipantsModal';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

type Props = HTMLAttributes<HTMLDivElement>;

const Participants: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const me = useStore($authorizedUser);
    const task = useStore($task);

    const [isAssigningAuthorizedUserToTask, setIsAssigningAuthorizedUserToTask] = useState(false);

    if (!task) {
        return null;
    }

    // Get is task delivered flag
    const isTaskDelivered = task.state === TaskState.DELIVERED;

    const handleAssignAuthorizedUserToTask = async () => {
        if (!me) {
            return;
        }

        setIsAssigningAuthorizedUserToTask(true);

        try {
            const taskRef = await joinTaskAsParticipantUser({
                taskId: task.id,
            });

            taskApi.addParticipation({
                input: [
                    {
                        id: me.user.id,
                        first_name: me.user.first_name,
                        image_hash: me.user.image_hash,
                        last_name: me.user.last_name,
                        company_position: 'Request participant',
                        team_participation: [], // TODO: add team_participation in authorizedUser (MeResource)
                    },
                ],
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsAssigningAuthorizedUserToTask(false);
        }
    };

    const renderHeader = () => {
        let isJoinButtonVisible = false;

        const isJoinAllowed = permissions.isRoot.task || permissions.has(TaskPermission.PARTICIPATION_JOIN);
        if (isJoinAllowed) {
            // User can join task

            const isAuthorizedUserTaskParticipant = !!task.participants.find(
                (participant) => participant.user.id === me!.user.id,
            );

            if (!isAuthorizedUserTaskParticipant) {
                // Authorized user is NOT participant of task yet
                isJoinButtonVisible = true;
            }
        }

        // Do not show "Join" button since task is delivered
        if (isTaskDelivered) {
            isJoinButtonVisible = false;
        }

        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Participants</Heading>

                {isJoinButtonVisible && (
                    <Button loading={isAssigningAuthorizedUserToTask} onClick={handleAssignAuthorizedUserToTask}>
                        Join
                    </Button>
                )}
            </Flex>
        );
    };

    const renderParticipants = () => {
        if (task.participants.length === 0) {
            return <DevText muted>No participants assigned yet</DevText>;
        }

        const isParticipantManageAllowed = permissions.isRoot.task || permissions.has(TaskPermission.PARTICIPATION_MANAGE);
        return task.participants.map((participant) => (
            <ParticipantListItem
                canRemove={isParticipantManageAllowed}
                className="mt-2"
                taskId={task.id}
                id={participant.user.id}
                key={participant.user.id}
                src={participant.user.image_hash}
                name={`${participant.user.first_name} ${participant.user.last_name}`}
                jobPosition={
                    participant.user.company_position.length > 0 ? participant.user.company_position : 'Request participant'
                }
            />
        ));
    };

    const renderAddParticipantButton = () => {
        // No point to "Add participant" if task is delivered
        if (isTaskDelivered) {
            return null;
        }

        // Permissions check
        const isParticipantManageAllowed = permissions.isRoot.task || permissions.has(TaskPermission.PARTICIPATION_MANAGE);
        if (!isParticipantManageAllowed) {
            return null;
        }

        return (
            <Button
                className="mt-2"
                minimal
                icon="plus"
                onClick={() => openModal({ ModalComponent: AddTaskParticipantsModal })}
            >
                Add participant
            </Button>
        );
    };

    return (
        <Card {...props}>
            {renderHeader()}
            {renderParticipants()}
            {renderAddParticipantButton()}
        </Card>
    );
};

export default Participants;
