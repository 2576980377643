import { Tooltip } from '@blueprintjs/core';
import React, { HTMLAttributes } from 'react';
import { MarkerPositioner, Marker } from './styled';

export interface PointMarkerProps {
    container: DOMRect | null;
    x: number;
    y: number;
    label?: string;
    content?: string;
}

export type Props = HTMLAttributes<HTMLDivElement> & PointMarkerProps;

const PointMarker: React.FC<Props> = ({ container, content, x, y, label, ...props }) => {
    if (!container) {
        return null;
    }

    const top = y * container.height;
    const left = x * container.width;
    const markerLabel = label ?? '';

    if (content) {
        return (
            <MarkerPositioner key={`${top}${left}`} $top={top} $left={left} {...props}>
                <Tooltip content={content}>
                    <Marker>{markerLabel}</Marker>
                </Tooltip>
            </MarkerPositioner>
        );
    } else {
        return (
            <MarkerPositioner key={`${top}${left}`} $top={top} $left={left} {...props}>
                <Marker>{markerLabel}</Marker>
            </MarkerPositioner>
        );
    }
};

export default PointMarker;
