import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $coreUser } from '../../store/states';
import { usePageTitle } from '@app/hooks';
import UserAuthRoles from '@containers/components/UserAuthRoles';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';

const CoreUserAuthRoles: React.FC = () => {
    const { changeTitle } = usePageTitle('Auth Roles');

    const coreUser = useStore($coreUser);

    useEffect(() => {
        if (!coreUser) {
            return;
        }

        changeTitle(`Auth Roles - ${coreUser.first_name} ${coreUser.last_name}`);
    }, [coreUser?.first_name, coreUser?.last_name]);

    if (!coreUser) {
        return null;
    }

    return (
        <UserAuthRoles
            userId={coreUser.id}
            userType={UserType.CORE}
        />
    );
};

export default CoreUserAuthRoles;
