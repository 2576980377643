import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { Button, Card, Divider, FormGroup, HTMLSelect, InputGroup, Intent } from '@blueprintjs/core';
import Overlay from '@components/Overlay';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import Flex from '@components/Flex';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from '@data/consts';

type Props = ModalProps<{}>;

const CreateAuthRoleModal: React.FC<Props> = ({ closeModal, data }) => {
    const navigate = useNavigate();

    const [isCreating, setIsCreating] = useState(false);

    const [title, setTitle] = useState('');
    const [userType, setUserType] = useState<UserType>(UserType.CORE);

    // O_o?
    if (!data) {
        closeModal?.();
        return null;
    }

    const handleCloseModal = () => {
        if (isCreating) {
            return;
        }

        closeModal?.();
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsCreating(true);

        // TODO: Handle error
        repository.authRole().create({ title: title, user_type: userType })
            .then((ref) => {
                closeModal?.();
                navigate(Endpoints.AUTH_ROLE.replace(':authRoleId', `${ref.id}`));
            })
            .catch(console.error)
            .finally(() => setIsCreating(false));

        return null;
    };

    return (
        <Overlay isOpen onClose={handleCloseModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">Create new auth role</Heading>
                        <DevText muted>After creation, role will be disabled by default</DevText>
                    </div>
                    <Button minimal icon="cross" onClick={handleCloseModal} disabled={isCreating} />
                </Flex>


                <form onSubmit={handleFormSubmit}>
                    <FormGroup className="mb-1" label="Role name">
                        <InputGroup
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-1" label="User Type">
                        <HTMLSelect
                            value={userType}
                            onChange={(e) => setUserType(e.target.value as UserType)}
                            options={[
                                { label: 'Member role', value: UserType.CORE },
                                { label: 'Client role', value: UserType.CLIENT },
                            ]}
                        />
                    </FormGroup>

                    <Divider />

                    <Flex align="center" justify="space-between">
                        <DevText muted>Create new role?</DevText>
                        <div>
                            <Button minimal onClick={closeModal} className="mr-1" disabled={isCreating}>
                                No, cancel
                            </Button>
                            <Button
                                intent={Intent.PRIMARY}
                                loading={isCreating}
                                disabled={title.trim().length === 0}
                                type="submit"
                            >
                                Yes, create
                            </Button>
                        </div>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );

};

export default CreateAuthRoleModal;
