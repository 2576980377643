import ConfirmationPopover from '@app/components/ConfirmationPopover';
import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import { openModal } from '@app/containers/modals/store/events';
import TaskDeliverableViewerModal, {
    TaskDeliverableViewerModalProps,
} from '@app/containers/pages/Task/modals/TaskDeliverableViewerModal';
import { $task } from '@app/containers/pages/Task/store/states';
import { Breadcrumb, BreadcrumbProps, Breadcrumbs, Button, Classes, Icon, Intent } from '@blueprintjs/core';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import TaskDeliverableListViewListItem from '../../components/TaskDeliverableListViewListItem';
import { rootPathLabel } from '../../consts';
import { useApproveDirectory, useApproveTaskDeliverable, useRemoveTaskDeliverable } from '../../hooks';
import { $taskDeliverablesTree } from '../../store/states';
import NonIdealState from '@components/NonIdealState';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from '@app/data/consts';
import { $permissions } from '@containers/store/states';
import { TaskDeliverablePermission } from 'dy-frontend-permissions/lib/permission';

const TaskDeliverablesApproved: React.FC = () => {
    const permissions = useStore($permissions);

    const navigate = useNavigate();

    const task = useStore($task);
    const taskDeliverablesTree = useStore($taskDeliverablesTree);
    const { approveDirectory } = useApproveDirectory();
    const { removeTaskDeliverable } = useRemoveTaskDeliverable();
    const { approveTaskDeliverable } = useApproveTaskDeliverable();

    const [approvingPath, setApprovingPath] = useState<string | null>(null);
    const [approvingDeliverableId, setApprovingDeliverableId] = useState<ID | null>(null);
    const [removingDeliverableId, setRemovingDeliverableId] = useState<ID | null>(null);

    if (!task || !taskDeliverablesTree) {
        return null;
    }

    const handleOpenTaskDeliverableViewerModal = (
        taskDeliverableId: ID,
        taskDeliverables: TaskDeliverableResource[]
    ) => {
        openModal<TaskDeliverableViewerModalProps>({
            ModalComponent: TaskDeliverableViewerModal,
            data: {
                taskId: task.id,
                taskDeliverableId,
                taskDeliverables: taskDeliverables,
            },
        });
    };

    const handleApproveTaskDeliverables = async (path: string) => {
        setApprovingPath(path);

        // Get last slash index
        const lastPathSlashIndex = path.lastIndexOf('/');

        // Get directory name from path
        const directoryName = path.slice(lastPathSlashIndex + 1); // "mycooldeliverable"

        // Get path without directory name
        const directoryPath = path.slice(0, lastPathSlashIndex); // "/directory"

        try {
            await approveDirectory({
                taskDeliverablesTree,
                directoryNameToApprove: directoryName,
                directoryPathToApprove: directoryPath,
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setApprovingPath(null);
        }
    };

    const handleRemoveTaskDeliverable = async (taskDeliverable: TaskDeliverableResource) => {
        setRemovingDeliverableId(taskDeliverable.id);

        try {
            await removeTaskDeliverable({
                path: taskDeliverable.path,
                taskDeliverableId: taskDeliverable.id,
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setRemovingDeliverableId(null);
        }
    };

    const handleApproveTaskDeliverable = async (taskDeliverable: TaskDeliverableResource) => {
        setApprovingDeliverableId(taskDeliverable.id);

        try {
            await approveTaskDeliverable({
                path: taskDeliverable.path,
                taskDeliverableId: taskDeliverable.id,
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setApprovingDeliverableId(null);
        }
    };

    const renderApproveAllButton = (path: string) => {
        const isAllowed = permissions.isRoot.taskDeliverable || permissions.has(TaskDeliverablePermission.APPROVE);
        if (!isAllowed) {
            return null;
        }

        const isApproving = approvingPath === path;

        return (
            <ConfirmationPopover
                title={`Approve all deliverables at "${path === '/' ? `/${rootPathLabel}` : path}"?`}
                description="When confirmed, client will be able to see deliverables"
                actions={[
                    <Button
                        intent={Intent.SUCCESS}
                        className={Classes.POPOVER_DISMISS}
                        onClick={() => handleApproveTaskDeliverables(path)}
                    >
                        Yes, approve
                    </Button>,
                ]}
            >
                <Button loading={isApproving} disabled={isApproving} className="ml-1" intent={Intent.PRIMARY}>
                    Approve All
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderApproveTaskDeliverableMenuItem = (taskDeliverable: TaskDeliverableResource) => {
        const isAllowed = permissions.isRoot.taskDeliverable || permissions.has(TaskDeliverablePermission.APPROVE);
        if (!isAllowed) {
            return null;
        }

        const isApprovingDeliverable = approvingDeliverableId === taskDeliverable.id;

        return (
            <Button
                minimal
                disabled={isApprovingDeliverable}
                icon="confirm"
                intent={Intent.SUCCESS}
                onClick={() => handleApproveTaskDeliverable(taskDeliverable)}
            >
                Approve
            </Button>
        );
    };

    const renderRemoveTaskDeliverableMenuItem = (taskDeliverable: TaskDeliverableResource) => {
        const isAllowed = permissions.isRoot.taskDeliverable || permissions.has(TaskDeliverablePermission.ARCHIVE);
        if (!isAllowed) {
            return null;
        }

        const isRemovingDeliverable = removingDeliverableId === taskDeliverable.id;

        return (
            <ConfirmationPopover
                title="Remove deliverable?"
                description="When confirmed, deliverable will be removed"
                actions={[
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={() => handleRemoveTaskDeliverable(taskDeliverable)}
                    >
                        Yes, remove
                    </Button>,
                ]}
            >
                <Button
                    minimal
                    disabled={isRemovingDeliverable}
                    className="ml-small"
                    icon="trash"
                    intent={Intent.DANGER}
                />
            </ConfirmationPopover>
        );
    };

    const renderDirectoryBlock = (path: string, taskDeliverables: TaskDeliverableResource[]) => {
        // Collect breadcrumbs
        const breadcrumbItems: BreadcrumbProps[] = [];
        breadcrumbItems.push({ text: rootPathLabel });
        if (path !== '/') {
            const parsedPath = path.split('/');
            for (let i = 1; i < parsedPath.length; i++) {
                breadcrumbItems.push({
                    text: parsedPath[i],
                });
            }
        }

        return (
            <div key={path} className="mb-2">
                <Flex className="mb-1" direction="row" align="center" justify="space-between">
                    <Breadcrumbs
                        items={breadcrumbItems}
                        currentBreadcrumbRenderer={({ text, ...restProps }: BreadcrumbProps) => (
                            <Breadcrumb {...restProps}>{text}</Breadcrumb>
                        )}
                    />

                    {renderApproveAllButton(path)}
                </Flex>

                <div>
                    {taskDeliverables
                        .filter((taskDeliverable) => taskDeliverable.approved_at === null)
                        .map((taskDeliverable, index) => (
                            <TaskDeliverableListViewListItem
                                approvementTagVisible={false}
                                className={index === 0 ? '' : 'mt-1'}
                                taskDeliverable={taskDeliverable}
                                onOpenViewer={() =>
                                    handleOpenTaskDeliverableViewerModal(taskDeliverable.id, taskDeliverables)
                                }
                                actions={
                                    <>
                                        {renderApproveTaskDeliverableMenuItem(taskDeliverable)}
                                        {renderRemoveTaskDeliverableMenuItem(taskDeliverable)}
                                    </>
                                }
                            />
                        ))}
                </div>
            </div>
        );
    };

    const renderList = () => {
        const directoryBlocks: JSX.Element[] = [];

        for (const path in taskDeliverablesTree.deliverables) {
            // Get task deliverables for path
            const taskDeliverablesForPath = Object.values(taskDeliverablesTree.deliverables[path]);

            // Check if any of the deliverables are not approved for path
            const isPendingApprovalTaskDeliverablesExist = taskDeliverablesForPath.some(
                (taskDeliverable) => taskDeliverable.approved_at === null
            );

            if (isPendingApprovalTaskDeliverablesExist) {
                directoryBlocks.push(renderDirectoryBlock(path, taskDeliverablesForPath));
            }
        }

        if (directoryBlocks.length === 0) {
            // Pending approval task deliverables are NOT found
            return (
                <NonIdealState
                    className="mt-2"
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No deliverables to approve
                        </Heading>
                    }
                    action={
                        <Button
                            className="mt-1"
                            intent={Intent.PRIMARY}
                            onClick={() => navigate(Endpoints.TASK_DELIVERABLES_ALL.replace(':taskId', task.id))}
                        >
                            Show all deliverables
                        </Button>
                    }
                />
            );
        }

        return <>{directoryBlocks}</>;
    };

    return <div>{renderList()}</div>;
};

export default TaskDeliverablesApproved;
