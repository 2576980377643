import domain from './domain';
import { resetAssignableAuthRoles, resetUserAuthRoles } from './events';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { UserAuthRoleResource } from 'dy-frontend-http-repository/lib/modules/User/resources';
import { AuthRoleListItemResource } from 'dy-frontend-http-repository/lib/modules/AuthRole/resources';

// List of user auth roles
export const $userAuthRoles = domain.createStore<
    CollectionResource<UserAuthRoleResource, {}> | null
>(null).reset(resetUserAuthRoles);

// List of assignable roles
export const $assignableAuthRoles = domain.createStore<CollectionResource<AuthRoleListItemResource, {}> | null>(null).reset(resetAssignableAuthRoles);
