import { PlatformCoreUserFilterData } from '../types';
import domain from './domain';

// Set page
export const setPage = domain.createEvent<number>('setPage');

// Set query hydration finished flag
export const setIsQueryHydrationFinished = domain.createEvent<boolean>('setIsQueryHydrationFinished');

// Set core user platform filter data
export const setPlatformCoreUserFilterData = domain.createEvent<PlatformCoreUserFilterData>(
    'setPlatformCoreUserFilterData'
);

// Reset core user
export const resetCoreUsers = domain.createEvent('resetCoreUsers');

// Reset page
export const resetPage = domain.createEvent('resetPage');

// Reset platform core user filter data
export const resetPlatformCoreUserFilterData = domain.createEvent('resetPlatformCoreUserFilterData');

// Reset is query hydration finished
export const resetIsQueryHydrationFinished = domain.createEvent('resetIsQueryHydrationFinished');

// Resets assignable auth roles list
export const resetAssignableAuthRoles = domain.createEvent('resetAssignableAuthRoles');
