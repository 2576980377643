import React, { useRef, useState } from 'react';
import { useStore } from 'effector-react';
import { $selectedTaskDeliverable } from '@pages/Task/modals/TaskDeliverableViewerModal/store/states';
import PdfViewer from '@components/PdfViewer';
import { PdfWrapper, Positioner, Wrapper } from './styled';
import { useSetPointMarker } from '../../../../hooks';
import PointMarkerBlock from '../PointMarkerBlock';

const TaskDeliverablePdfViewer: React.FC = () => {
    const { handleSetPointMarker } = useSetPointMarker();
    const ref = useRef<HTMLDivElement | null>(null);

    const selectedTaskDeliverable = useStore($selectedTaskDeliverable);

    const [numPages, setNumPages] = useState<number | null>(null);
    const [renderedPagesCounter, setRenderedPagesCounter] = useState(0);

    if (!selectedTaskDeliverable || !selectedTaskDeliverable.file) {
        return null;
    }

    const isPdfViewerReady = () => numPages !== null && renderedPagesCounter === numPages && ref.current;

    const handlePutMarker = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isPdfViewerReady()) {
            return;
        }
        handleSetPointMarker(event);
    };

    return (
        <Wrapper>
            <Positioner>
                <PdfWrapper ref={ref} onClick={handlePutMarker}>
                    <PdfViewer
                        src={selectedTaskDeliverable.file.url}
                        documentProps={{
                            onDocumentLoadSuccess: (numPages) => setNumPages(numPages),
                            inputRef: ref,
                        }}
                        pageProps={{
                            onRenderSuccess: () => setRenderedPagesCounter((prevPagesCounter) => prevPagesCounter + 1),
                        }}
                    />

                    {isPdfViewerReady() && <PointMarkerBlock containerRef={ref} />}
                </PdfWrapper>
            </Positioner>
        </Wrapper>
    );
};

export default TaskDeliverablePdfViewer;
