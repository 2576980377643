import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../store/states';
import { resetEditingTaskMessage, resetTaskMessages } from './store/events';
import TaskMessageEditor from './components/TaskMessageEditor';
import TaskMessageList from './components/TaskMessageList';
import { usePageTitle } from '@app/hooks';

const TaskMessages: React.FC = () => {
    const { changeTitle } = usePageTitle('Request Messages');

    const task = useStore($task);

    useEffect(() => {
        if (!task) {
            return;
        }

        changeTitle(`Request Messages - ${task.title}`);
    }, [task?.title]);

    useEffect(() => {
        return () => {
            resetTaskMessages();
            resetEditingTaskMessage();
        };
    }, []);

    if (!task) {
        return null;
    }

    return (
        <div>
            <TaskMessageEditor className="mb-4" />
            <TaskMessageList />
        </div>
    );
};

export default TaskMessages;
1;
