import { Button, Intent, Menu, MenuItem, Popover, Tag } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import Flex from '@components/Flex';
import DevText from '@components/Text';
import { imageHashPreview } from '@data/consts';
import { taskDeliverableCommentsApi } from '@pages/Task/modals/TaskDeliverableViewerModal/store/apis';
import {
    archiveTaskDeliverableComment,
    markTaskDeliverableCommentAsResolved,
    removeTaskDeliverableCommentResolvedStatus,
    restoreTaskDeliverableComment,
} from '@pages/Task/modals/TaskDeliverableViewerModal/store/effects';
import {
    TaskDeliverableCommentResource,
} from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { TimeUtils, UnitUtils } from 'dy-frontend-shared/lib/utils';
import moment from 'moment';
import React, { forwardRef, HTMLAttributes } from 'react';
import { setQueuedVideoTimelinePositionMS } from '../../../../store/events';
import { PointMarkerByTaskDeliverableCommentIdMap } from '../../../../types';
import { Wrapper } from './styled';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';
import { TaskDeliverableCommentPermission } from 'dy-frontend-permissions/lib/permission';

export interface TaskDeliverableCommentProps {
    isHighlighted?: boolean;
    isChildComment?: boolean;
    comment: TaskDeliverableCommentResource;
    markersMap: PointMarkerByTaskDeliverableCommentIdMap;
    onEdit: () => void;
    onSuccessfulArchive?: () => void;
    onReply?: () => void;
}

export type Props = HTMLAttributes<HTMLDivElement> & TaskDeliverableCommentProps;

const TaskDeliverableComment = forwardRef<HTMLDivElement, Props>(
    (
        {
            comment,
            isChildComment = false,
            isHighlighted = false,
            markersMap,
            onEdit,
            onReply,
            onSuccessfulArchive,
            ...props
        },
        ref,
    ) => {
        const permissions = useStore($permissions);

        const renderCommentHeader = () => {
            const isTaskDeliverableArchived = !!comment.archived_at;

            const isTaskDeliverableCommentResolved = !!comment.resolved_at;

            // Auth
            const isUpdateAllowed = permissions.isRoot.taskDeliverableComment || permissions.has(TaskDeliverableCommentPermission.UPDATE);
            const isRestoreAllowed = permissions.isRoot.taskDeliverableComment || permissions.has(TaskDeliverableCommentPermission.RESTORE);
            const isRemoveAllowed = permissions.isRoot.taskDeliverableComment || permissions.has(TaskDeliverableCommentPermission.ARCHIVE);
            const isResolveAllowed = permissions.isRoot.taskDeliverableComment || permissions.has(TaskDeliverableCommentPermission.RESOLVE);
            const isUnresolveAllowed = permissions.isRoot.taskDeliverableComment || permissions.has(TaskDeliverableCommentPermission.UNRESOLVE);
            const shouldShowMoreButton = (
                isUpdateAllowed
                || isRestoreAllowed
                || isRemoveAllowed
                || isResolveAllowed
                || isUnresolveAllowed
            );

            const renderUpdateCommentMenuItem = () => {
                if (comment.archived_at !== null) {
                    // Comment archived

                    return null;
                }

                if (!isUpdateAllowed) {
                    // Update is not allowed
                    return null;
                }

                return <MenuItem text="Edit" icon="edit" onClick={onEdit} />;
            };

            const renderRemoveCommentMenuItem = () => {
                if (isTaskDeliverableArchived) {
                    // Comment archived

                    return null;
                }

                if (!isRemoveAllowed) {
                    // Remove is not allowed
                    return null;
                }

                const handleRemoveComment = () => {
                    archiveTaskDeliverableComment(comment.id)
                        .then(() => {
                            if (onSuccessfulArchive) {
                                onSuccessfulArchive();
                            }

                            taskDeliverableCommentsApi.archiveTaskDeliverableComment({
                                id: comment.id,
                                parentTaskDeliverableCommentId: comment.parent_task_deliverable_comment_id,
                            });
                        })
                        .catch((e) => {
                            // TODO: handle error
                            console.error(e);
                        });
                };

                return <MenuItem intent={Intent.DANGER} text="Remove" icon="trash" onClick={handleRemoveComment} />;
            };

            const renderRestoreCommentMenuItem = () => {
                if (!isTaskDeliverableArchived) {
                    // Comment is NOT archived

                    return null;
                }

                if (!isRestoreAllowed) {
                    // Restore is not allowed
                    return null;
                }

                const handleRestoreComment = () => {
                    restoreTaskDeliverableComment(comment.id)
                        .then(() =>
                            taskDeliverableCommentsApi.restoreTaskDeliverableCommentsByIdTaskDeliverableComment({
                                id: comment.id,
                                parentTaskDeliverableCommentId: comment.parent_task_deliverable_comment_id,
                            }),
                        )
                        .catch((e) => {
                            // TODO: handle error
                            console.error(e);
                        });
                };

                return <MenuItem text="Restore" icon="confirm" onClick={handleRestoreComment} />;
            };

            const renderResolveCommentMenuItem = () => {
                if (isTaskDeliverableCommentResolved) {
                    // Comment is already resolved

                    return null;
                }

                if (!isResolveAllowed) {
                    // Resolve is not allowed
                    return null;
                }

                const handleResolveComment = () => {
                    markTaskDeliverableCommentAsResolved(comment.id)
                        .then(() =>
                            taskDeliverableCommentsApi.markCommentAsResolved({
                                id: comment.id,
                                parentTaskDeliverableCommentId: comment.parent_task_deliverable_comment_id,
                            }),
                        )
                        .catch((e) => {
                            // TODO: handle error
                            console.error(e);
                        });
                };

                return (
                    <MenuItem
                        intent={Intent.SUCCESS}
                        text="Mark as done"
                        icon="confirm"
                        onClick={handleResolveComment}
                    />
                );
            };

            const renderUnresolveCommentMenuItem = () => {
                if (!isTaskDeliverableCommentResolved) {
                    // Comment is NOT resolved

                    return null;
                }

                if (!isUnresolveAllowed) {
                    // Resolve is not allowed
                    return null;
                }

                const handleUnresolveComment = () => {
                    removeTaskDeliverableCommentResolvedStatus(comment.id)
                        .then(() =>
                            taskDeliverableCommentsApi.removeCommentResolvedStatus({
                                id: comment.id,
                                parentTaskDeliverableCommentId: comment.parent_task_deliverable_comment_id,
                            }),
                        )
                        .catch((e) => {
                            // TODO: handle error
                            console.error(e);
                        });
                };

                return (
                    <MenuItem
                        intent={Intent.SUCCESS}
                        text="Return back to work"
                        icon="undo"
                        onClick={handleUnresolveComment}
                    />
                );
            };

            const renderMoreButton = () => {
                if (!shouldShowMoreButton) {
                    // No actions allowed
                    return null;
                }

                return (
                    <Popover
                        className="ml-1"
                        content={
                            <Menu>
                                {renderResolveCommentMenuItem()}
                                {renderUpdateCommentMenuItem()}
                                {renderUnresolveCommentMenuItem()}
                                {renderRemoveCommentMenuItem()}
                                {renderRestoreCommentMenuItem()}
                            </Menu>
                        }
                    >
                        <Button icon="more" minimal />
                    </Popover>
                );
            };

            const renderMarker = () => {
                if (isChildComment) {
                    return null;
                }

                if (comment.marker_point) {
                    // Point marker (images/gif, PDF)

                    const marker = markersMap[comment.id];
                    if (!marker) {
                        // There is no such marker for current comment in the point markers map

                        return null;
                    }

                    return <Tag>{marker.index}</Tag>;
                }

                if (comment.marker_time) {
                    // Time marker (Videos)

                    return (
                        <Tag
                            interactive
                            className="mr-small"
                            onClick={() => setQueuedVideoTimelinePositionMS(comment.marker_time)}
                        >
                            {TimeUtils.toHHMMSS(`${UnitUtils.msToSec(comment.marker_time)}`)}
                        </Tag>
                    );
                }

                return null;
            };

            const renderResolvedTag = () => {
                if (comment.resolved_at) {
                    // Comment resolved
                    return (
                        <Tag className="mr-small" intent={Intent.SUCCESS} icon="confirm">
                            Done
                        </Tag>
                    );
                }

                return null;
            };

            const renderRemovedTag = () => {
                if (comment.archived_at) {
                    // Comment resolved
                    return (
                        <Tag className="mr-small" intent={Intent.DANGER}>
                            Removed
                        </Tag>
                    );
                }

                return null;
            };

            // Get avatar src
            let avatarSrc: string | null = null;
            if (comment.user.image_hash) {
                avatarSrc = imageHashPreview.userImage(comment.user.image_hash, ImageHashPreviewSize.SM);
            }

            return (
                <Flex className="mb-2" align="flex-start" justify="space-between">
                    <Flex align="flex-start" justify="space-between">
                        <Avatar
                            className="mr-1"
                            width="40px"
                            height="40px"
                            alt={`${comment.user.first_name} ${comment.user.last_name}`}
                            src={avatarSrc}
                        />

                        <Flex direction="column" justify="center">
                            <DevText>
                                {comment.user.first_name} {comment.user.last_name}
                            </DevText>

                            <DevText inline small>
                                {moment(comment.created_at).format('h:mm A MMM D')}
                            </DevText>
                        </Flex>
                    </Flex>

                    <Flex align="center">
                        {renderRemovedTag()}
                        {renderResolvedTag()}
                        {renderMarker()}
                        {renderMoreButton()}
                    </Flex>
                </Flex>
            );
        };

        const renderReplyButton = () => {
            if (comment.archived_at !== null) {
                // Do not allow replying to archived comment

                return null;
            }

            if (!onReply) {
                return null;
            }

            // Check auth
            const isAllowed = permissions.isRoot.taskDeliverableComment || permissions.has(TaskDeliverableCommentPermission.CREATE);
            if (!isAllowed) {
                return null;
            }

            return (
                <Button minimal intent={Intent.NONE} onClick={onReply}>
                    Reply
                </Button>
            );
        };

        return (
            <Wrapper
                $isArchived={comment.archived_at !== null}
                $isChildComment={isChildComment}
                $isHighlighted={isHighlighted}
                ref={ref}
                {...props}
            >
                {renderCommentHeader()}
                <DevText className="mb-1">{comment.content}</DevText>
                {renderReplyButton()}
            </Wrapper>
        );
    },
);

export default TaskDeliverableComment;
