import { createApi } from 'effector';
import { $userIdentity } from './states';
import moment from 'moment';

export const userIdentityApi = createApi($userIdentity, {
    removeVerification: (store) => {
        if (!store) {
            // User identity is NOT fetched yet
            return null;
        }

        return {
            ...store,
            verified_at: null,
        };
    },
    verify: (store) => {
        if (!store) {
            // User identity is NOT fetched yet
            return null;
        }

        const verifiedAt = moment().utc().format();

        return {
            ...store,
            verified_at: verifiedAt,
            initial_verification_at:
                store.initial_verification_at !== null ? store.initial_verification_at : verifiedAt,
        };
    },
    setEmail: (store, payload: { email: string }) => {
        if (!store) {
            // User identity is NOT fetched yet
            return null;
        }

        return { ...store, email: payload.email };
    },
    enablePasswordAuth: (store) => {
        if (!store) {
            // User identity is NOT fetched yet
            return null;
        }

        return { ...store, is_password_auth_setup: true };
    },
    disablePasswordAuth: (store) => {
        if (!store) {
            // User identity is NOT fetched yet
            return null;
        }

        return { ...store, is_password_auth_setup: false };
    },
    enableGoogleAuth: (store) => {
        if (!store) {
            // User identity is NOT fetched yet
            return null;
        }

        return { ...store, is_google_auth_setup: true };
    },
    disableGoogleAuth: (store) => {
        if (!store) {
            // User identity is NOT fetched yet
            return null;
        }

        return { ...store, is_google_auth_setup: false };
    },
    // TODO: use enum for reason
    shutdown: (store, payload: { reason: string }) => {
        if (!store) {
            // User identity is NOT fetched yet
            return null;
        }

        const shutdownAt = moment().utc().format();

        return { ...store, shutdown_at: shutdownAt, shutdown_reason: payload.reason };
    },
});
