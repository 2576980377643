import ConfirmationPopover from '@app/components/ConfirmationPopover';
import FileViewerModal, { FileViewerModalProps } from '@app/containers/modals/FileViewerModal';
import { openModal } from '@app/containers/modals/store/events';
import { $permissions } from '@app/containers/store/states';
import { Button, Classes, Colors, Icon, Intent, Menu, MenuItem, Popover, Tag } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import FileTilePreview from '@components/FileTilePreview';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import RichText from '@components/RichText';
import RouterLink from '@components/RouterLink';
import DevText from '@components/Text';
import { Endpoints, imageHashPreview } from '@data/consts';
import { taskMessagesApi } from '@pages/Task/pages/TaskMessages/store/apis';
import { pinTaskMessage, removeTaskMessage, unpinTaskMessage } from '@pages/Task/pages/TaskMessages/store/effects';
import { setEditingTaskMessage } from '@pages/Task/pages/TaskMessages/store/events';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { FileAttachmentResource } from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { TaskMessageListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { HTMLAttributes, useState } from 'react';
import { StyledCard, TaskMessageContent, TaskMessageHeader } from './styled';
import { TaskMessagePermission } from 'dy-frontend-permissions/lib/permission';

export interface TaskMessageProps {
    isAuthorizedUserAuthor?: boolean;
    alignment: 'left' | 'right';
    taskMessage: TaskMessageListItemResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & TaskMessageProps;

const TaskMessage: React.FC<Props> = ({ isAuthorizedUserAuthor = false, alignment, taskMessage, ...props }) => {
    const permissions = useStore($permissions);

    const isHeaderVisible = taskMessage.is_pinned;

    const [isPinning, setIsPinning] = useState(false);
    const [isUnpinning, setIsUnpinning] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const renderTaskMessageHeader = () => {
        if (!isHeaderVisible) {
            return null;
        }

        return (
            <TaskMessageHeader>
                <DevText>
                    <Icon className="mr-small" icon="pin" intent={Intent.PRIMARY} />
                    Pinned
                </DevText>
            </TaskMessageHeader>
        );
    };

    const renderAttachments = () => {
        if (taskMessage.files.length === 0) {
            return null;
        }

        const renderViewFile = (index: number) => {
            const handleViewFile = () => {
                openModal<FileViewerModalProps>({
                    ModalComponent: FileViewerModal,
                    data: {
                        files: taskMessage.files.map((f) => ({
                            name: f.file.original_name,
                            extension: f.file.extension,
                            size: f.file.size,
                            src: f.file.url,
                        })),
                        currentFileIndex: index,
                    },
                });
            };

            return <Button minimal icon="eye-open" onClick={() => handleViewFile()} />;
        };

        const renderFile = (attachment: FileAttachmentResource, index: number) => {
            let src: string | undefined = undefined;

            if (attachment.file.preview_url) {
                src = attachment.file.preview_url;
            } else {
                src = attachment.file.url;
            }

            return (
                <FileTilePreview
                    fileName={attachment.file.original_name}
                    src={src}
                    fileSize={25}
                    extension={attachment.file.extension}
                    actions={renderViewFile(index)}
                />
            );
        };

        return (
            <Grid container>
                {taskMessage.files.map((attachment, index) => (
                    <Grid className="mt-2" key={attachment.file.id} lg={6} xs={12}>
                        {renderFile(attachment, index)}
                    </Grid>
                ))}
            </Grid>
        );
    };

    const renderAttachedFiles = () => {
        if (taskMessage.files.length === 0) {
            return null;
        }

        return renderAttachments();
    };

    const renderContentHeader = () => {
        const renderRouterLink = (label: string) => {
            let url: string;

            switch (taskMessage.user!.type) {
                case UserType.CLIENT:
                    url = Endpoints.CLIENT_TASKS.replace(':clientId', `${taskMessage.user!.id}`);
                    break;
                case UserType.CORE:
                    url = Endpoints.CORE_USER_TASKS.replace(':coreUserId', `${taskMessage.user!.id}`);
                    break;
                default:
                    // TODO: URL should lead to Workspace page
                    url = Endpoints.TASKS;
                    break;
            }

            return (
                <RouterLink color={Colors.WHITE} to={url}>
                    {label}
                </RouterLink>
            );
        };

        const renderInternalTag = () => {
            if (!taskMessage.is_internal) {
                return null;
            }

            return (
                <Tag className="ml-1" intent={Intent.PRIMARY} icon="tag">
                    Private note
                </Tag>
            );
        };

        const renderMoreButton = () => {
            const isPinManageAllowed = permissions.isRoot.taskMessage || permissions.has(TaskMessagePermission.PIN_MANAGE);
            const isUpdateAllowed = permissions.isRoot.taskMessage || permissions.has(TaskMessagePermission.UPDATE);
            const isArchiveAllowed = permissions.isRoot.taskMessage || permissions.has(TaskMessagePermission.ARCHIVE);

            const isMoreButtonVisible = isPinManageAllowed || isUpdateAllowed || isArchiveAllowed;
            if (!isMoreButtonVisible) {
                return null;
            }

            const handleUpdate = () => {
                setEditingTaskMessage(taskMessage);
            };

            const handleRemove = async () => {
                setIsRemoving(true);

                try {
                    await removeTaskMessage(taskMessage.id);
                    taskMessagesApi.remove(taskMessage.id);
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                } finally {
                    setIsRemoving(false);
                }
            };

            const handlePin = async () => {
                setIsPinning(true);

                try {
                    await pinTaskMessage(taskMessage.id);
                    taskMessagesApi.pin(taskMessage.id);
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                } finally {
                    setIsPinning(false);
                }
            };

            const handleUnpin = async () => {
                setIsUnpinning(true);
                try {
                    await unpinTaskMessage(taskMessage.id);
                    taskMessagesApi.unpin(taskMessage.id);
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                } finally {
                    setIsUnpinning(false);
                }
            };

            const renderUpdateMenuItem = () => {
                if (!isUpdateAllowed) {
                    // Task message update is NOT allowed
                    return null;
                }

                return <MenuItem icon="updated" text="Update" onClick={handleUpdate} />;
            };

            const renderPinMenuItem = () => {
                if (taskMessage.is_pinned) {
                    // Task is pinned
                    return null;
                }

                if (!isPinManageAllowed) {
                    // Task message pin is NOT allowed
                    return null;
                }

                return <MenuItem disabled={isPinning} icon="pin" text="Pin" onClick={() => handlePin()} />;
            };

            const renderUnpinMenuItem = () => {
                if (!taskMessage.is_pinned) {
                    // Task is NOT pinned
                    return null;
                }

                if (!isPinManageAllowed) {
                    // Task message unpin is NOT allowed
                    return null;
                }

                return <MenuItem disabled={isUnpinning} icon="pin" text="Unpin" onClick={() => handleUnpin()} />;
            };

            const renderRemoveMenuItem = () => {
                if (!isArchiveAllowed) {
                    // Task message remove is NOT allowed
                    return null;
                }

                return (
                    <ConfirmationPopover
                        fill
                        title="Are you sure you want to remove this message?"
                        description="When confirmed, this message will be deleted"
                        actions={[
                            <Button intent={Intent.DANGER} className={Classes.POPOVER_DISMISS} onClick={handleRemove}>
                                Yes, delete message
                            </Button>,
                        ]}
                    >
                        <MenuItem
                            shouldDismissPopover={false}
                            disabled={isRemoving}
                            icon="remove"
                            text="Remove"
                            intent={Intent.DANGER}
                        />
                    </ConfirmationPopover>
                );
            };

            const renderMoreButtonMenu = () => {
                return (
                    <Menu>
                        {renderUpdateMenuItem()}
                        {renderUnpinMenuItem()}
                        {renderPinMenuItem()}
                        {renderRemoveMenuItem()}
                    </Menu>
                );
            };

            return (
                <Popover content={renderMoreButtonMenu()}>
                    <Button minimal className="ml-1" icon="more" />
                </Popover>
            );
        };

        const renderCreatedAtLabel = () => {
            return (
                <DevText muted>
                    {moment(taskMessage.created_at).format('D MMMM YYYY HH:mm')} (
                    {moment(taskMessage.created_at).fromNow()})
                </DevText>
            );
        };

        const renderRightBlock = () => {
            return (
                <Flex align="center">
                    {renderInternalTag()}
                    {renderMoreButton()}
                </Flex>
            );
        };

        const renderAuthorizedUserHeader = () => {
            return (
                <Flex justify="space-between">
                    <div>
                        {renderRouterLink('You')}
                        {renderCreatedAtLabel()}
                    </div>

                    {renderRightBlock()}
                </Flex>
            );
        };

        const renderUserHeader = () => {
            // Get avatar src
            let avatarSrc: string | null = null;
            if (taskMessage.user?.image_hash) {
                avatarSrc = imageHashPreview.userImage(taskMessage.user.image_hash, ImageHashPreviewSize.SM);
            }

            return (
                <Flex justify="space-between">
                    <Flex align="center">
                        <Avatar className="mr-1" alt="Avatar" src={avatarSrc} />

                        <Flex direction="column" justify="center">
                            {renderRouterLink(`${taskMessage.user!.first_name} ${taskMessage.user!.last_name}`)}
                            {renderCreatedAtLabel()}
                        </Flex>
                    </Flex>

                    {renderRightBlock()}
                </Flex>
            );
        };

        if (isAuthorizedUserAuthor) {
            return renderAuthorizedUserHeader();
        } else {
            return renderUserHeader();
        }
    };

    return (
        <StyledCard $isInternal={taskMessage.is_internal} $alignment={alignment} {...props}>
            {/* Message header */}
            {renderTaskMessageHeader()}

            <TaskMessageContent $headerVisible={isHeaderVisible}>
                {/* Content header */}
                <div className="mb-1">{renderContentHeader()}</div>

                <RichText value={taskMessage.content} />

                {/* Attached files */}
                {renderAttachedFiles()}
            </TaskMessageContent>
        </StyledCard>
    );
};

export default TaskMessage;
