import PasswordLockButton from '@app/components/PasswordLockButton';
import Select from '@app/components/Select';
import { useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import {
    getStringEmailValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, Intent, MenuItem, Spinner } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import { Endpoints } from '@data/consts';
import { ModalProps } from '@modals/types';
import { createCoreUser, fetchAssignableAuthRoles } from '@pages/CoreUsers/store/effects';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { AuthRoleListItemResource } from 'dy-frontend-http-repository/lib/modules/AuthRole/resources';
import { CoreUserRef } from 'dy-frontend-http-repository/lib/modules/CoreUser/refs';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $assignableAuthRoles } from '../../store/states';
import { $permissions } from '@containers/store/states';

type Props = ModalProps;

const coreUserRoleIdValidators = [];
const coreUserFirstNameValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];
const coreUserLastNameValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];
const coreUserEmailValidators = [getStringRequiredValidator(), getStringEmailValidator()];
const coreUserPasswordValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 8 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];

const CreateClientModal: React.FC<Props> = ({ closeModal }) => {
    const permissions = useStore($permissions);

    const navigate = useNavigate();

    const assignableAuthRoles = useStore($assignableAuthRoles);

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const coreUserFirstName = useTextFormField({
        id: 'core-first-name',
        validators: coreUserFirstNameValidators,
        initialValue: '',
    });

    const coreUserLastName = useTextFormField({
        id: 'core-last-name',
        validators: coreUserLastNameValidators,
        initialValue: '',
    });

    const coreUserEmail = useTextFormField({
        id: 'core-email',
        validators: coreUserEmailValidators,
        initialValue: '',
    });

    const coreUserPassword = useTextFormField({
        id: 'core-password',
        validators: coreUserPasswordValidators,
        initialValue: '',
    });

    const coreUserSelectedRole = useCustomSelectFormField<AuthRoleListItemResource | null>({
        id: 'core-user-auth-role',
        validators: coreUserRoleIdValidators,
        initialValue: null,
        formatValue: (value) => (value ? value.id : ''),
    });

    const form = useForm<CoreUserRef>({
        fields: [coreUserFirstName, coreUserLastName, coreUserEmail, coreUserPassword],
        apiCall: async () => {
            try {
                const coreUserRef = await createCoreUser({
                    first_name: coreUserFirstName.value,
                    last_name: coreUserLastName.value,
                    email: coreUserEmail.value,
                    password: coreUserPassword.value,
                    auth_role_id: coreUserSelectedRole.value ? coreUserSelectedRole.value.id : null,
                });

                return { response: coreUserRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: ({ response: coreUserRef }) => {
            closeModal?.();
            navigate(Endpoints.CORE_USER_TASKS.replace(':coreUserId', coreUserRef.id));
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    useEffect(() => {
        if (!permissions.isEnabled.authRole) {
            return;
        }

        fetchAssignableAuthRoles({ user_type: UserType.CORE }).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, []);

    const renderForm = () => {
        if (!assignableAuthRoles && permissions.isEnabled.authRole) {
            return (
                <Flex direction="row" align="center" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        const renderRoleIdSelect = () => {
            if (!permissions.isEnabled.authRole) {
                return null;
            }

            if (!assignableAuthRoles || assignableAuthRoles.items.length === 0) {
                // No auth roles to select
                return null;
            }

            let selectButtonText: string | null = null;
            if (coreUserSelectedRole.value) {
                selectButtonText = coreUserSelectedRole.value.title;
            }

            const renderItem = (item: AuthRoleListItemResource, { handleClick }: ItemRendererProps) => {
                const isMenuItemActive = item.id === coreUserSelectedRole.value?.id;

                return <MenuItem active={isMenuItemActive} key={item.id} text={item.title} onClick={handleClick} />;
            };

            const handleItemSelect = (item: AuthRoleListItemResource) => {
                coreUserSelectedRole.handleChange(item);
            };

            return (
                <FormGroup
                    label="Auth Role"
                    intent={!!coreUserSelectedRole.error ? Intent.DANGER : Intent.NONE}
                    helperText={coreUserSelectedRole.error}
                >
                    <Select<AuthRoleListItemResource>
                        items={assignableAuthRoles?.items}
                        itemRenderer={renderItem}
                        onItemSelect={handleItemSelect}
                        popoverProps={{
                            matchTargetWidth: true,
                            usePortal: false,
                        }}
                        selectButtonProps={{
                            fill: true,
                            rightIcon: 'double-caret-vertical',
                            placeholder: 'Select auth role',
                            text: selectButtonText,
                        }}
                    />
                </FormGroup>
            );
        };

        return (
            <form onSubmit={form.handleFormSubmit}>
                <Grid container>
                    <Grid lg={6}>
                        <InputFormField
                            field={coreUserFirstName}
                            formGroupProps={{ label: 'First name' }}
                            inputProps={{ placeholder: 'Enter first name' }}
                        />
                    </Grid>
                    <Grid lg={6}>
                        <InputFormField
                            field={coreUserLastName}
                            formGroupProps={{ label: 'Last name' }}
                            inputProps={{ placeholder: 'Enter last name' }}
                        />
                    </Grid>
                    <Grid lg={12}>
                        <InputFormField
                            field={coreUserEmail}
                            formGroupProps={{ label: 'Email' }}
                            inputProps={{ placeholder: 'Enter email' }}
                        />
                    </Grid>

                    <Grid lg={12}>
                        <InputFormField
                            field={coreUserPassword}
                            formGroupProps={{ label: 'Password' }}
                            inputProps={{
                                placeholder: 'Enter password',
                                type: isPasswordVisible ? 'text' : 'password',
                                rightElement: (
                                    <PasswordLockButton
                                        isPasswordVisible={isPasswordVisible}
                                        changePasswordVisibility={setIsPasswordVisible}
                                    />
                                ),
                            }}
                        />
                    </Grid>

                    <Grid lg={12}>{renderRoleIdSelect()}</Grid>
                </Grid>

                <Flex justify="flex-end">
                    <Button className="mr-1" outlined onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        disabled={form.hasFieldErrors}
                        loading={form.isSubmitting}
                        type="submit"
                        intent={Intent.PRIMARY}
                    >
                        Create
                    </Button>
                </Flex>
            </form>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create member account</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                {renderForm()}
            </Card>
        </Overlay>
    );
};

export default CreateClientModal;
