import AvatarUploaderNEW from '@app/components/AvatarUploaderNEW';
import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import TeamTag from '@app/containers/components/TeamTag';
import ImageCropperModal, { ImageCropperModalProps } from '@app/containers/modals/ImageCropperModal';
import { openModal } from '@app/containers/modals/store/events';
import { coreUserApi } from '@app/containers/pages/CoreUser/store/apis';
import { removeCoreUserImage, uploadAndSetCoreUserImage } from '@app/containers/pages/CoreUser/store/effects';
import { $coreUser } from '@app/containers/pages/CoreUser/store/states';
import { authorizedUserApi } from '@app/containers/store/apis';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { imageHashPreview } from '@app/data/consts';
import { getFileAllowedTypesValidator, getFileMaxSizeValidator } from '@app/hooks/validation/functions';
import { Card, Tag } from '@blueprintjs/core';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { HTMLAttributes, useState } from 'react';
import { CoreUserPermission, SelfUserPermission } from 'dy-frontend-permissions/lib/permission';

const coreUserAvatarValidators = [
    getFileAllowedTypesValidator({ allowedTypes: ['jpg', 'png', 'jpeg', 'svg', 'webp', 'gif'] }),
    getFileMaxSizeValidator({ maxSize: 25000000 }),
];

export type Props = HTMLAttributes<HTMLElement>;

const CoreUserBadge: React.FC<Props> = ({ ...props }) => {
    const permissions = useStore($permissions);

    const me = useStore($authorizedUser);
    const coreUser = useStore($coreUser);

    const [isUploading, setIsUploading] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    if (!coreUser || !me) {
        return null;
    }

    const isPhotoUpdateAllowed = (
        coreUser.id === me.user.id ?
            permissions.isRoot.selfUser || permissions.has(SelfUserPermission.INFO_UPDATE)
            : permissions.isRoot.coreUser || permissions.has(CoreUserPermission.INFO_UPDATE)
    );

    const handleRemoveImage = async () => {
        setIsRemoving(true);

        try {
            const userRef = await removeCoreUserImage(coreUser.id);
            coreUserApi.removeImage();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsRemoving(false);
        }
    };

    const handleUploadImage = async (file: File) => {
        setIsUploading(true);

        try {
            const userImageHashRef = await uploadAndSetCoreUserImage({ id: coreUser.id, file: file });
            coreUserApi.setImageHash(userImageHashRef);

            // Check if updated user is authorized user and update it's avatar
            if (me && me.user.id === coreUser.id) {
                authorizedUserApi.setImageHash(userImageHashRef);
            }
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsUploading(false);
        }
    };

    const handleSelectFile = (file: File) => {
        const isImage = file.type.split('/')[0] === 'image';
        if (!isImage) {
            alert('Not an image');
            return;
        }

        const isSvg = file.type === 'image/svg+xml';

        // Non-svg files can be adjusted
        if (!isSvg) {
            openModal<ImageCropperModalProps>({
                ModalComponent: ImageCropperModal,
                data: {
                    inputImageUrl: URL.createObjectURL(file),
                    aspect: 1,
                    shape: 'round',
                    onCropComplete: handleUploadImage,
                },
            });
            return;
        }

        // Svg are uploaded directly
        handleUploadImage(file);
    };

    const renderAvatar = () => {
        // Get avatar alt
        const avatarAlt = `${coreUser.first_name} ${coreUser.last_name}`;

        // Get avatar src
        let avatarSrc: string | null = null;
        if (coreUser.image_hash) {
            avatarSrc = imageHashPreview.userImage(coreUser.image_hash, ImageHashPreviewSize.SM);
        }

        return (
            <AvatarUploaderNEW
                loading={isUploading || isRemoving}
                width="70px"
                height="70px"
                validators={coreUserAvatarValidators}
                avatarProps={{
                    alt: avatarAlt,
                    src: avatarSrc,
                }}
                fileUploadProgressProps={{
                    spinnerProps: {
                        size: 40,
                    },
                }}
                onFileSelect={handleSelectFile}
                onFileRemove={handleRemoveImage}
                disabled={!isPhotoUpdateAllowed}
            />
        );
    };

    const renderCompanyPosition = () => {
        // Check if company position no empty
        if (coreUser.company_position.trim().length === 0) {
            return null;
        }

        return <DevText muted>{coreUser.company_position}</DevText>;
    };

    const renderTeamParticipationInformation = () => {
        if (coreUser.team_participation.length === 0) {
            return <DevText muted>No team</DevText>;
        } else {
            return coreUser.team_participation.map((teamParticipation, index) => (
                <TeamTag
                    id={teamParticipation.team.id}
                    key={teamParticipation.team.id}
                    className={index === 0 ? '' : 'mt-small'}
                    name={teamParticipation.team.title}
                    color={teamParticipation.team.color}
                />
            ));
        }
    };

    return (
        <Card {...props}>
            <Flex className="mb-2" align="center">
                <div className="mr-1">{renderAvatar()}</div>

                <Flex direction="column">
                    <Heading className="mb-small" type="h4">
                        {coreUser.first_name} {coreUser.last_name}
                    </Heading>
                    <DevText>{coreUser.company_name}</DevText>

                    {renderCompanyPosition()}
                </Flex>
            </Flex>

            <div>
                <div className="mb-2">{renderTeamParticipationInformation()}</div>

                <Flex className="mb-1" align="center" justify="space-between">
                    <DevText muted>Last activity:</DevText>
                    {/* TODO: replace with last_activity date whenever implemented on backend */}
                    <DevText className="ml-1">{moment(coreUser.created_at).format('D MMMM YYYY')}</DevText>
                </Flex>

                <Flex align="center" justify="space-between">
                    <DevText muted>Registered:</DevText>
                    <DevText className="ml-1">{moment(coreUser.created_at).format('D MMMM YYYY')}</DevText>
                </Flex>
            </div>
        </Card>
    );
};

export default CoreUserBadge;
