import React, { useEffect } from 'react';
import MenuItemSection from '@containers/components/Layout/components/NextMenu/components/MenuItemSection';
import MenuItem from '@containers/components/Layout/components/NextMenu/components/MenuItem';
import { Endpoints } from '@data/consts';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';
import {
    AuthRolePermission,
    PlanPermission,
    TaskCategoryFeaturedBlockPermission,
    TaskCategoryGroupPermission,
    TaskMessageTemplatePermission,
} from 'dy-frontend-permissions/lib/permission';

const DotYetiMenuItems: React.FC = () => {
    const permissions = useStore($permissions);

    useEffect(() => {
        console.log('permissions: ', permissions);
    }, [permissions]);

    const items = {
        task: {
            kanban: permissions.isEnabled.task,
            all: permissions.isEnabled.task,
            client_feedback: permissions.isEnabled.task,
        },
        clients: {
            all: permissions.isEnabled.clientUser,
        },
        members: {
            all: permissions.isEnabled.coreUser,
        },
        teams: {
            all: permissions.isEnabled.team,
        },
        categories: {
            all: permissions.isEnabled.taskCategory,
            groups:
                permissions.isRoot.taskCategoryGroup ||
                (permissions.isEnabled.taskCategoryGroup &&
                    (permissions.has(TaskCategoryGroupPermission.CREATE) ||
                        permissions.has(TaskCategoryGroupPermission.UPDATE) ||
                        permissions.has(TaskCategoryGroupPermission.ARCHIVE) ||
                        permissions.has(TaskCategoryGroupPermission.RESTORE) ||
                        permissions.has(TaskCategoryGroupPermission.COMPLEXITY_MANAGE))),
            featured:
                permissions.isRoot.taskCategoryFeaturedBlock ||
                (permissions.isEnabled.taskCategoryFeaturedBlock &&
                    (permissions.has(TaskCategoryFeaturedBlockPermission.CREATE) ||
                        permissions.has(TaskCategoryFeaturedBlockPermission.UPDATE) ||
                        permissions.has(TaskCategoryFeaturedBlockPermission.ARCHIVE) ||
                        permissions.has(TaskCategoryFeaturedBlockPermission.RESTORE))),
        },
        plans:
            permissions.isRoot.plan ||
            (permissions.isEnabled.plan &&
                (permissions.has(PlanPermission.CREATE) ||
                    permissions.has(PlanPermission.INFO_UPDATE) ||
                    permissions.has(PlanPermission.PUBLISH) ||
                    permissions.has(PlanPermission.ARCHIVE) ||
                    permissions.has(PlanPermission.RESTORE) ||
                    permissions.has(PlanPermission.ATTACHMENT_MANAGE))),
        task_message_templates:
            permissions.isRoot.taskMessageTemplate ||
            (permissions.isEnabled.taskMessageTemplate &&
                (permissions.has(TaskMessageTemplatePermission.CREATE) ||
                    permissions.has(TaskMessageTemplatePermission.UPDATE) ||
                    permissions.has(TaskMessageTemplatePermission.ARCHIVE) ||
                    permissions.has(TaskMessageTemplatePermission.RESTORE))),
        auth_role:
            permissions.isRoot.authRole ||
            (permissions.isEnabled.authRole &&
                (permissions.has(AuthRolePermission.CREATE) ||
                    permissions.has(AuthRolePermission.UPDATE) ||
                    permissions.has(AuthRolePermission.ENABLE_STATE_MANAGE) ||
                    permissions.has(AuthRolePermission.ARCHIVE) ||
                    permissions.has(AuthRolePermission.RESTORE))),
    };

    // TODO: Remove this hack
    const isSettingsAvailable = items.plans || items.task_message_templates || items.auth_role;

    return (
        <>
            <div className="mb-2">
                <MenuItem icon="control" title="Dashboard" link={Endpoints.APP} />
            </div>

            <MenuItemSection collapsible title="Requests" className="mb-3">
                <MenuItem icon="full-stacked-chart" title="Kanban board" link={Endpoints.TASK_KANBAN_BOARD} />
                <MenuItem icon="form" title="All requests" link={Endpoints.TASKS} />
                <MenuItem icon="chart" title="Feedback summary" link={Endpoints.TASK_CLIENT_FEEDBACK} />
            </MenuItemSection>

            <MenuItemSection collapsible title="Clients" className="mb-3">
                {items.clients.all && <MenuItem icon="person" title="All clients" link={Endpoints.CLIENTS} />}
            </MenuItemSection>

            {/* <MenuItemSection collapsible title="Workspaces" className="mb-3">
                <MenuItem icon="cloud" title="All workspaces" link={Endpoints.WORKSPACES} />
            </MenuItemSection> */}

            <MenuItemSection collapsible title="Members" className="mb-3">
                {items.members.all && (
                    <MenuItem icon="inherited-group" title="All members" link={Endpoints.CORE_USERS} />
                )}
            </MenuItemSection>

            <MenuItemSection collapsible title="Teams" className="mb-3">
                {items.teams.all && <MenuItem icon="team" title="All teams" link={Endpoints.TEAMS} />}
                {/*<MenuItem*/}
                {/*    icon="full-circle"*/}
                {/*    iconColor="#0cb4ce"*/}
                {/*    title="Yeti Blue"*/}
                {/*    label="Team member"*/}
                {/*    onClick={() => alert('Not implemented')}*/}
                {/*/>*/}
            </MenuItemSection>

            <MenuItemSection collapsible title="Request categories" className="mb-3">
                {items.categories.all && (
                    <MenuItem icon="new-grid-item" title="All categories" link={Endpoints.TASK_CATEGORIES} />
                )}
                {items.categories.groups && (
                    <MenuItem icon="comparison" title="Category groups" link={Endpoints.TASK_CATEGORY_GROUPS} />
                )}
                {items.categories.featured && (
                    <MenuItem
                        icon="horizontal-distribution"
                        title="Featured categories"
                        link={Endpoints.TASK_CATEGORY_FEATURED_BLOCKS}
                    />
                )}
            </MenuItemSection>

            {isSettingsAvailable && (
                <MenuItemSection collapsible title="Settings" className="mb-3">
                    {items.plans && <MenuItem icon="box" title="Plans" link={Endpoints.PLANS} />}
                    {items.task_message_templates && (
                        <MenuItem
                            icon="align-left"
                            title="Request message templates"
                            link={Endpoints.TASK_MESSAGE_TEMPLATES}
                        />
                    )}
                    {items.auth_role && <MenuItem icon="key" title="Auth roles" link={Endpoints.AUTH_ROLES} />}
                </MenuItemSection>
            )}
        </>
    );
};

export default DotYetiMenuItems;
