import domain from './domain';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { UserAuthRoleResource } from 'dy-frontend-http-repository/lib/modules/User/resources';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { UserRef } from 'dy-frontend-http-repository/lib/modules/User/refs';
import {
    AuthRoleAssignableFilterInput,
    AuthRoleFilterInput,
} from 'dy-frontend-http-repository/lib/modules/AuthRole/inputs';
import { AuthRoleListItemResource } from 'dy-frontend-http-repository/lib/modules/AuthRole/resources';

// Fetches list of user auth roles
export const fetchUserAuthRoles = domain.createEffect<ID, CollectionResource<UserAuthRoleResource, {}>>({
    name: 'fetchUserAuthRoles',
    handler: async (userId) => {
        return await repository.user().getAuthRoleList(userId);
    },
});

export const attachUserAuthRole = domain.createEffect<{ userId: ID; roleId: ID }, UserRef>({
    name: 'addUserAuthRole',
    handler: async ({ userId, roleId }) => {
        return await repository.user().attachAuthRole(userId, roleId);
    },
});

// Removes role
export const removeUserAuthRole = domain.createEffect<{ userId: ID; roleId: ID }, UserRef>({
    name: 'removeUserAuthRole',
    handler: async ({ userId, roleId }) => {
        return await repository.user().removeAuthRole(userId, roleId);
    },
});

// Get list of roles, which can be assigned
export const fetchAssignableAuthRoles = domain.createEffect<
    AuthRoleAssignableFilterInput,
    CollectionResource<AuthRoleListItemResource, {}>
>({
    name: 'fetchAssignableAuthRoles',
    handler: async (filter) => {
        return await repository.authRole().getAssignable(filter);
    },
});
