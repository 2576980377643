import { openModal } from '@app/containers/modals/store/events';
import TaskDeliverableViewerModal, {
    TaskDeliverableViewerModalProps,
} from '@app/containers/pages/Task/modals/TaskDeliverableViewerModal';
import { Button, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { $task } from '@pages/Task/store/states';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useState } from 'react';
import { useApproveTaskDeliverable, useRemoveTaskDeliverable } from '../../hooks';
import TaskDeliverableListViewListItem from '../TaskDeliverableListViewListItem';
import { $permissions } from '@containers/store/states';
import { TaskDeliverablePermission } from 'dy-frontend-permissions/lib/permission';

export interface TaskDeliverableListViewProps {
    taskDeliverables: TaskDeliverableResource[];
}

export type Props = TaskDeliverableListViewProps & HTMLAttributes<HTMLDivElement>;

const TaskDeliverableListView: React.FC<Props> = ({ taskDeliverables, ...props }) => {
    const permissions = useStore($permissions);

    const { removeTaskDeliverable } = useRemoveTaskDeliverable();
    const { approveTaskDeliverable } = useApproveTaskDeliverable();

    const task = useStore($task);

    const [openedMenuId, setOpenedMenuId] = useState<ID | null>(null);
    const [approvingDeliverableId, setApprovingDeliverableId] = useState<ID | null>(null);
    const [removingDeliverableId, setRemovingDeliverableId] = useState<ID | null>(null);

    // TODO: may be add different tables for different task deliverable types

    if (!task) {
        return null;
    }

    const handleCloseMenu = () => {
        setOpenedMenuId(null);
    };

    const handleToggleMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>, taskDeliverableId: ID) => {
        e.stopPropagation();

        if (openedMenuId) {
            // Close menu
            handleCloseMenu();
        } else {
            // Open menu
            setOpenedMenuId(taskDeliverableId);
        }
    };

    const handleRemoveTaskDeliverable = async (taskDeliverable: TaskDeliverableResource) => {
        setRemovingDeliverableId(taskDeliverable.id);

        try {
            await removeTaskDeliverable({
                path: taskDeliverable.path,
                taskDeliverableId: taskDeliverable.id,
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setRemovingDeliverableId(null);
        }
    };

    const handleApproveTaskDeliverable = async (taskDeliverable: TaskDeliverableResource) => {
        setApprovingDeliverableId(taskDeliverable.id);

        try {
            await approveTaskDeliverable({
                path: taskDeliverable.path,
                taskDeliverableId: taskDeliverable.id,
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setApprovingDeliverableId(null);
        }
    };

    const handleOpenTaskDeliverableViewerModal = (taskDeliverableId: ID) => {
        openModal<TaskDeliverableViewerModalProps>({
            ModalComponent: TaskDeliverableViewerModal,
            data: {
                taskId: task.id,
                taskDeliverableId,
                taskDeliverables: taskDeliverables,
            },
        });
    };

    const renderTaskDeliverableMenu = (taskDeliverable: TaskDeliverableResource) => {
        const isApproveAllowed = permissions.isRoot.taskDeliverable || permissions.has(TaskDeliverablePermission.APPROVE);
        const isArchiveAllowed = permissions.isRoot.taskDeliverable || permissions.has(TaskDeliverablePermission.ARCHIVE);
        if (!isApproveAllowed && !isArchiveAllowed) {
            return null;
        }

        const renderRemoveTaskDeliverableMenuItem = () => {
            if (!isArchiveAllowed) {
                return null;
            }

            const isRemovingDeliverable = removingDeliverableId === taskDeliverable.id;

            return (
                <MenuItem
                    disabled={isRemovingDeliverable}
                    text="Remove"
                    icon="trash"
                    intent={Intent.DANGER}
                    onClick={() => handleRemoveTaskDeliverable(taskDeliverable)}
                />
            );
        };

        const renderApproveTaskDeliverableMenuItem = () => {
            if (!isApproveAllowed) {
                return null;
            }

            const isApprovingDeliverable = approvingDeliverableId === taskDeliverable.id;

            return (
                <MenuItem
                    disabled={isApprovingDeliverable}
                    intent={Intent.SUCCESS}
                    text="Approve"
                    icon="confirm"
                    onClick={() => handleApproveTaskDeliverable(taskDeliverable)}
                />
            );
        };

        return (
            <Menu>
                {renderApproveTaskDeliverableMenuItem()}
                {renderRemoveTaskDeliverableMenuItem()}
            </Menu>
        );
    };

    const renderTaskDeliverableMenuButton = (taskDeliverable: TaskDeliverableResource) => {
        const isMenuOpened = openedMenuId === taskDeliverable.id;
        const isMoreButtonDisabled =
            (approvingDeliverableId !== null && approvingDeliverableId === taskDeliverable.id) ||
            (removingDeliverableId !== null && removingDeliverableId === taskDeliverable.id);

        const content = renderTaskDeliverableMenu(taskDeliverable);
        if (!content) {
            return null;
        }

        return (
            <Popover
                isOpen={isMenuOpened}
                content={content}
                onClose={handleCloseMenu}
            >
                <Button
                    minimal
                    loading={isMoreButtonDisabled}
                    icon="more"
                    onClick={(e) => handleToggleMenu(e, taskDeliverable.id)}
                />
            </Popover>
        );
    };

    const renderList = () => {
        return taskDeliverables.map((taskDeliverable, index) => (
            <TaskDeliverableListViewListItem
                className={index === 0 ? '' : 'mt-1'}
                key={`${taskDeliverable.id}-${taskDeliverable.created_at}`}
                taskDeliverable={taskDeliverable}
                actions={renderTaskDeliverableMenuButton(taskDeliverable)}
                onOpenViewer={() => handleOpenTaskDeliverableViewerModal(taskDeliverable.id)}
            />
        ));
    };

    return <div {...props}>{renderList()}</div>;
};

export default TaskDeliverableListView;
