import domain from './domain';
import { AuthRoleRef } from 'dy-frontend-http-repository/lib/modules/AuthRole/refs';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { AuthRoleFilterInput, CreateAuthRoleInput } from 'dy-frontend-http-repository/lib/modules/AuthRole/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { AuthRoleListItemResource } from 'dy-frontend-http-repository/lib/modules/AuthRole/resources';

export const createAuthRole = domain.createEffect<CreateAuthRoleInput, AuthRoleRef>({
    name: 'createAuthRole',
    handler: async (input) => {
        return await repository.authRole().create(input);
    },
});

export const fetchAuthRoles = domain.createEffect<
    AuthRoleFilterInput | undefined,
    CollectionResource<AuthRoleListItemResource, {}>
>({
    name: 'fetchAuthRoles',
    handler: async (input) => {
        return await repository.authRole().get(input);
    },
});
