import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { TaskDeliverablesParsedInformation } from '../types';
import { resetCurrentPathLocation, resetTaskDeliverables, resetTaskDeliverablesTree } from './events';
import domain from './domain';

// Current path location
export const $currentPathLocation = domain.createStore<string>('/').reset(resetCurrentPathLocation);

// Task deliverables tree
export const $taskDeliverablesTree = domain
    .createStore<TaskDeliverablesParsedInformation | null>(null)
    .reset(resetTaskDeliverablesTree);

// Task deliverables
export const $taskDeliverables = domain
    .createStore<CollectionResource<TaskDeliverableResource, {}> | null>(null)
    .reset(resetTaskDeliverables);
