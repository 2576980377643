import React, { HTMLAttributes } from 'react';
import {
    TaskCategoryGroupResource,
    VirtualLicenceTaskCategoryAttachmentResource,
} from 'dy-frontend-http-repository/lib/modules/VirtualLicence/resources';
import { Card, Elevation, Intent, Tag } from '@blueprintjs/core';
import DevText from '@components/Text';
import Heading from '@components/Heading';

export interface VirtualLicenceTaskCategoryListProps {
    taskCategoryAttachments: VirtualLicenceTaskCategoryAttachmentResource[];
}

export type Props = HTMLAttributes<HTMLDivElement> & VirtualLicenceTaskCategoryListProps;

// TODO: consider moving to container for reusing on ClientLicences page
// Section: List of task category attachments
const VirtualLicenceTaskCategoryList: React.FC<Props> = ({ taskCategoryAttachments, ...props }) => {
    // Renders list of task category groups, associated with task category
    const renderItemGroupList = (itemId: ID, groups: TaskCategoryGroupResource[]) => {
        return (
            <div className="mt-1">
                {groups.map((g) => (
                    <Tag key={`group-${itemId}-${g.id}`}>{g.title}</Tag>
                ))}
            </div>
        );
    };

    // Renders task category attachment item
    const renderItem = (item: VirtualLicenceTaskCategoryAttachmentResource) => {
        return (
            <Card compact className="mb-1" elevation={Elevation.ONE}>
                <DevText>
                    <Tag minimal intent={item.is_excluded ? Intent.DANGER : Intent.PRIMARY} className="mr-1">
                        {item.is_excluded ? 'Excluded' : 'Included'}
                    </Tag>
                    {item.task_category.title}
                </DevText>
                {renderItemGroupList(item.task_category.id, item.task_category.groups)}
            </Card>
        );
    };

    // Renders current task category attachment list
    const renderList = (list: VirtualLicenceTaskCategoryAttachmentResource[]) => {
        if (!list.length) {
            return (
                <div className="mt-1">
                    <DevText muted>No specific request category rules are created...</DevText>
                </div>
            );
        }

        return list.map((i) => <div key={`virtual-licence-task-category-${i.task_category.id}`}>{renderItem(i)}</div>);
    };

    return (
        <div {...props}>
            <Heading type="h6" className="mb-small">
                Specific request category rules
            </Heading>
            <DevText muted className="mb-1">
                Request category rules
            </DevText>
            {renderList(taskCategoryAttachments)}
        </div>
    );
};

export default VirtualLicenceTaskCategoryList;
