import PageTabs from '@app/components/PageTabs';
import { $permissions } from '@app/containers/store/states';
import { Endpoints } from '@app/data/consts';
import { TaskMessagePermission, TaskPermission } from 'dy-frontend-permissions/lib/permission';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { tabs } from './consts';

type Props = HTMLAttributes<HTMLDivElement>;

const Tabs: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const { taskId } = useParams();

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const tabVisibilityPermissions = useMemo<{
        [pageEndpoint in keyof typeof Endpoints]?: boolean;
    }>(() => {

        return {
            [Endpoints.TASK_INFORMATION]: true,
            [Endpoints.TASK_MESSAGES]: (
                // Enabled or root
                permissions.has(TaskMessagePermission._ENABLED) || permissions.has(TaskMessagePermission.ROOT)
            ),

            // TODO: Permission
            [Endpoints.TASK_DELIVERABLES]: true,
            [Endpoints.TASK_DEV_DELIVERABLES]: true,

            // If root on task, or one of the management permissions are present
            [Endpoints.TASK_MANAGEMENT]: (
                permissions.has(TaskPermission.ROOT)
                || (
                    permissions.has(TaskPermission.COMPLEXITY_MANAGE)
                    || permissions.has(TaskPermission.PRIORITY_MANAGE)
                )
            ),
        };
    }, []);

    const getFormattedPath = (path: string) => {
        return path.replace(':taskId', taskId!);
    };

    const filteredTabsByPermissions = tabs.filter((tab) => {
        const tabPermission: boolean | undefined =
            tabVisibilityPermissions[tab.value as (typeof Endpoints)[keyof typeof Endpoints]];

        if (tabPermission === undefined) {
            // Tab visibility permission was NOT found for tab, do NOT show it then
            return false;
        }

        return tabPermission;
    });

    return (
        <PageTabs
            pathname={pathname}
            visibleAmount={5}
            tabs={filteredTabsByPermissions}
            onSelectTab={navigate}
            pathFormatter={getFormattedPath}
            {...props}
        />
    );
};

export default Tabs;
