import React, { HTMLAttributes, useState } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { useTaskStateTransition } from '@pages/Task/hooks';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const RevisionRequired: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const isTransitionAllowed = permissions.isRoot.task || permissions.has(TaskPermission.STATE_TRANSITION);

    const { handleTransitionTaskState } = useTaskStateTransition();

    const authorizedUser = useStore($authorizedUser);
    const task = useStore($task);

    const [transitioningTo, setTransitionTo] = useState<TaskState | null>(null);

    if (!authorizedUser) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    // TODO: Render helper actions

    const handleTransitionToState = async (state: TaskState) => {
        try {
            setTransitionTo(state);
            const res = await handleTransitionTaskState(task.id, state);
        } catch (e) {
            // TODO: handle error
        } finally {
            setTransitionTo(null);
        }
    };

    const renderInRevisionTransitionButton = () => {
        const isAllowed = permissions.isRoot.task || (isTransitionAllowed && permissions.has(TaskPermission.STATE_TRANSITION_IN_REVISION));
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="outdated"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.IN_REVISION}
                onClick={() => handleTransitionToState(TaskState.IN_REVISION)}
            >Start another revision</Button>
        );
    };

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Heading type="h4" className="mb-1">Revision Required</Heading>
                <Flex>
                    <div>{renderInRevisionTransitionButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>Additional revisions were requested for latest provided deliverables.</DevText>
            <DevText muted>TODO: Write additional hint</DevText>
        </Card>
    );
};

export default RevisionRequired;
