import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { $permissions } from '@app/containers/store/states';
import { useUpload } from '@app/hooks';
import { FileInformation } from '@app/hooks/useUpload/types';
import { getFileMaxSizeValidator } from '@app/hooks/validation/functions';
import { Button, Classes, Intent } from '@blueprintjs/core';
import FileTilePreview from '@components/FileTilePreview';
import FileUploadButton from '@components/FileUploadButton';
import Flex from '@components/Flex';
import RichEditor from '@components/RichEditor';
import richEditorPlugins from '@components/RichEditor/plugins';
import { AdditionalControl, AttachedFile } from '@components/RichEditor/types';
import { openModal } from '@modals/store/events';
import { $task } from '@pages/Task/store/states';
import { FilePurpose, HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { RichTextFormat } from 'dy-frontend-shared/lib/data/valueObjects';
import { FileUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import TaskMessageTemplatePickerModal, {
    TaskMessageTemplatePickerModalProps,
} from '../../modals/TaskMessageTemplatePickerModal';
import { taskMessagesApi } from '../../store/apis';
import { createTaskMessage, fetchTaskMessage, fetchTaskMessages, updateTaskMessage } from '../../store/effects';
import { setEditingTaskMessage } from '../../store/events';
import { $editingTaskMessage, $taskMessages } from '../../store/states';
import { amountOfTaskMessagesOnPage } from '../TaskMessageList/consts';
import { TaskMessagePermission } from 'dy-frontend-permissions/lib/permission';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';

export type Props = HTMLAttributes<HTMLDivElement>;

const taskMessageFileValidators = [getFileMaxSizeValidator({ maxSize: 125000000 })];

const TaskMessageEditor: React.FC<Props> = ({ ...props }) => {
    const permissions = useStore($permissions);

    const task = useStore($task);
    const editingTaskMessage = useStore($editingTaskMessage);
    const taskMessages = useStore($taskMessages);

    const [isUpserting, setIsUpserting] = useState(false);
    const [contentForcedValue, setContentForcedValue] = useState<string | null>(null);
    const [content, setContent] = useState('');
    const [attachedFiles, setAttachedFiles] = useState<AttachedFile[]>([]);

    const {
        files: taskMessageFiles,
        upload: handleUploadTaskMessageFiles,
        isUploading,
    } = useUpload({
        clearAllFilesOnUploadSuccess: true,
        validators: taskMessageFileValidators,
        onFilesUploadSuccess: (files) => {
            setAttachedFiles((prevFiles) => [...getRichEditorAttachedFiles(files), ...prevFiles]);
        },
    });

    const isTaskMessageContentEmpty =
        new RichTextFormat(content, richEditorPlugins).getContentAsPlainText().trim().length === 0;

    useEffect(() => {
        if (editingTaskMessage) {
            const editingTaskMessageAttachedFiles: AttachedFile[] = [];
            for (let i = 0; i < editingTaskMessage.files.length; i++) {
                const attachment = editingTaskMessage.files[i];
                editingTaskMessageAttachedFiles.push({
                    id: attachment.file.id,
                    key: `${attachment.index}`,
                    progress: 1,
                    loading: false,
                    extension: attachment.file.extension,
                    name: attachment.file.original_name,
                    size: attachment.file.size,
                    src: attachment.file.preview_url ?? attachment.file.url,
                });
            }

            setAttachedFiles(editingTaskMessageAttachedFiles);
            setContentForcedValue(editingTaskMessage.content);
        }
    }, [editingTaskMessage]);

    if (!task) {
        return null;
    }

    // Permission
    const isAllowedToCreateMessage = (permissions.isRoot.taskMessage || permissions.has(TaskMessagePermission.CREATE));
    if (!isAllowedToCreateMessage) {
        return null;
    }

    const handleUpdate = async () => {
        if (isTaskMessageContentEmpty) {
            return;
        }

        if (!editingTaskMessage) {
            return;
        }

        setIsUpserting(true);

        try {
            // Get valid file IDs array
            const fileIds = attachedFiles
                .map((file) => (file.id ? file.id : null))
                .filter((id) => id !== null) as ID[];

            // Create task message
            const taskMessageRef = await updateTaskMessage({
                taskMessageId: editingTaskMessage?.id,
                input: {
                    content: content,
                    file_ids: fileIds,
                    is_internal: editingTaskMessage?.is_internal,
                },
            });

            // Fetch task message
            const taskMessage = await fetchTaskMessage({ taskMessageId: taskMessageRef.id });
            taskMessagesApi.update({ taskMessage });

            setContent('');
            setAttachedFiles([]);
            setEditingTaskMessage(null);
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Task messages for task with ID of ${task.id} were not found`,
                    intent: Intent.DANGER,
                });
            }
        } finally {
            setIsUpserting(false);
        }
    };

    const handleCreate = async (isInternal: boolean) => {
        if (isTaskMessageContentEmpty) {
            return;
        }

        setIsUpserting(true);

        try {
            // Get valid file IDs array
            const fileIds = attachedFiles
                .map((file) => (file.id ? file.id : null))
                .filter((id) => id !== null) as ID[];

            // Create task message
            await createTaskMessage({
                task_id: task.id,
                content: content,
                file_ids: fileIds,
                is_internal: isInternal,
            });

            // Fetch task messages
            const fetchedTaskMessages = await fetchTaskMessages({
                pagination: {
                    _cursor: {
                        direction: 'asc',
                        limit: amountOfTaskMessagesOnPage,
                        start: taskMessages && taskMessages.items.length > 0 ? taskMessages.items[0].id : undefined,
                    },
                },
                filter: {
                    task_id: task.id,
                    is_archived: '0',
                },
            });

            taskMessagesApi.addAtStartAfterSuccessfulCreation({ taskMessages: fetchedTaskMessages });
            setContent('');
            setAttachedFiles([]);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsUpserting(false);
        }
    };

    const getRichEditorAttachedFiles = (files: FileInformation[]): AttachedFile[] => {
        if (files.length === 0) {
            return [];
        }

        return files.map((file) => {
            let src: string | undefined;

            if (file.resource) {
                // Priority is set for <code>preview_url</code>, but if it doesn't exist, then we have to use <code>url</code>
                if (file.resource.preview_url) {
                    src = file.resource.preview_url;
                } else {
                    src = file.resource.url;
                }
            }

            return {
                id: file.resource?.id,
                key: file.key,
                loading: file.progress !== 1 || !src,
                progress: file.progress,
                name: file.file.name,
                size: file.file.size,
                extension: FileUtils.getFileExtension(file.file.name),
                src,
            };
        });
    };

    const richEditorUploadingAttachedFiles = getRichEditorAttachedFiles(taskMessageFiles);

    const getAdditionalActions = (): AdditionalControl[] => {
        const additionalActions: AdditionalControl[] = [];

        // Attach file to task message
        additionalActions.push({
            alignment: 'right',
            Component: (
                <FileUploadButton
                    multiple
                    icon="paperclip"
                    className="ml-small"
                    minimal
                    onAttachFiles={(files) => {
                        handleUploadTaskMessageFiles(FilePurpose.TASK_MESSAGE_ATTACHMENT, files);
                    }}
                >
                    Attach
                </FileUploadButton>
            ),
        });

        // Add button for using message template
        if (permissions.isEnabled.taskMessageTemplate) {
            additionalActions.push({
                alignment: 'right',
                Component: (
                    <Button
                        icon="align-left"
                        className="ml-small"
                        minimal
                        onClick={() =>
                            openModal<TaskMessageTemplatePickerModalProps>({
                                ModalComponent: TaskMessageTemplatePickerModal,
                                data: {
                                    onUseTemplate: (content) => setContentForcedValue(content),
                                },
                            })
                        }
                    >
                        Use template
                    </Button>
                ),
            });
        }

        return additionalActions;
    };

    const renderRemoveFileAction = (fileKey: string) => {
        const handleRemoveFile = () => {
            setAttachedFiles((prevTaskMessageAttachedFiles) =>
                prevTaskMessageAttachedFiles.filter(
                    (taskMessageAttachedFile) => taskMessageAttachedFile.key !== fileKey,
                ),
            );
        };

        return (
            <ConfirmationPopover
                title="Are you sure you want to remove file?"
                description="When confirmed, file will be removed"
                actions={[
                    <Button intent={Intent.DANGER} className={Classes.POPOVER_DISMISS} onClick={handleRemoveFile}>
                        Yes, remove file
                    </Button>,
                ]}
            >
                <Button minimal icon="trash" intent={Intent.DANGER} />
            </ConfirmationPopover>
        );
    };

    const renderFileActions = (fileKey: string) => {
        return <>{renderRemoveFileAction(fileKey)}</>;
    };

    const renderFileItem = (file: AttachedFile) => {
        return (
            <FileTilePreview
                fileName={file.name}
                fileSize={file.size}
                extension={file.extension}
                src={file.src}
                alt={file.alt}
                loading={file.loading}
                progress={file.progress}
                actions={renderFileActions(file.key)}
            />
        );
    };

    const renderCancelEditingButton = () => {
        if (!editingTaskMessage) {
            // Task message for editing was NOT set
            return null;
        }

        const handleCancelTaskMessage = () => {
            setEditingTaskMessage(null);
            setContent('');
            setAttachedFiles([]);
        };

        return (
            <Button
                className="ml-1"
                outlined
                icon="small-cross"
                intent={Intent.DANGER}
                onClick={handleCancelTaskMessage}
            >
                Cancel editing
            </Button>
        );
    };

    const renderSendTaskMessageButton = () => {
        const isButtonLoading = isUploading || isUpserting;
        const isButtonDisabled = isTaskMessageContentEmpty;

        if (editingTaskMessage) {
            // Can't create task message because task message for update is present
            return null;
        }

        return (
            <Button
                loading={isButtonLoading}
                disabled={isButtonDisabled}
                className="ml-1"
                icon="send-message"
                intent={Intent.PRIMARY}
                onClick={() => handleCreate(false)}
            >
                Send
            </Button>
        );
    };

    const renderSendTaskNoteButton = () => {
        const isButtonLoading = isUploading || isUpserting;
        const isButtonDisabled = isTaskMessageContentEmpty;

        if (editingTaskMessage) {
            // Can't create task message because task message for update is present
            return null;
        }

        // Ensure allowed to create internal messages
        const isInternalMessageAllowed = (
            permissions.isRoot.taskMessage
            || permissions.has(TaskMessagePermission.CREATE_INTERNAL)
        );
        if (!isInternalMessageAllowed) {
            return null;
        }

        return (
            <Button
                minimal
                loading={isButtonLoading}
                disabled={isButtonDisabled}
                className="ml-1"
                icon="annotation"
                onClick={() => handleCreate(true)}
            >
                Send as private note
            </Button>
        );
    };

    const renderUpdateButton = () => {
        const isButtonLoading = isUploading || isUpserting;
        const isButtonDisabled = isTaskMessageContentEmpty;

        if (!editingTaskMessage) {
            // Can't update task message because task message for update is NOT present
            return null;
        }

        // Update permissions
        const isUpdateAllowed = (
            permissions.isRoot.taskMessage
            || permissions.has(TaskMessagePermission.UPDATE)
        );
        if (!isUpdateAllowed) {
            // Update task message is NOT allowed
            return null;
        }

        return (
            <Button
                disabled={isButtonDisabled}
                loading={isButtonLoading}
                className="ml-1"
                icon="send-message"
                intent={Intent.PRIMARY}
                onClick={handleUpdate}
            >
                Update
            </Button>
        );
    };

    // Primary action renderer
    const renderPrimaryAction = () => {
        return (
            <Flex>
                {renderCancelEditingButton()}
                {renderUpdateButton()}
                {renderSendTaskNoteButton()}
                {renderSendTaskMessageButton()}
            </Flex>
        );
    };

    return (
        <div {...props}>
            <RichEditor
                className="mb-4"
                placeholder="Enter task message"
                value={content}
                files={[...attachedFiles, ...richEditorUploadingAttachedFiles]}
                forceValue={contentForcedValue ? contentForcedValue : null}
                additionalActions={getAdditionalActions()}
                renderFileItem={renderFileItem}
                primaryAction={renderPrimaryAction()}
                onChange={(data) => setContent(data)}
                onForceValueSet={() => setContentForcedValue(null)}
            />

            {/* <Divider /> */}
        </div>
    );
};

export default TaskMessageEditor;
