import Link from '@app/components/Link';
import { Button, Card, Elevation, Icon, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import Flex from '@components/Flex';
import { openModal } from '@modals/store/events';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import React, { HTMLAttributes, useState } from 'react';
import { useRemoveDirectory } from '../../hooks';
import UpsertTaskDeliverableDirectoryModal, {
    UpsertTaskDeliverableDirectoryModalProps,
} from '../../pages/TaskDeliverablesAll/modals/UpsertTaskDeliverableDirectoryModal';
import { TaskDeliverablesParsedInformation } from '../../types';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';

export interface TaskDeliverableDirectoryListItemProps {
    taskDeliverablesTree: TaskDeliverablesParsedInformation;
    directoryPath: string;
    directoryName: string;
    onVisitDirectory: () => void;
}

export type Props = TaskDeliverableDirectoryListItemProps & HTMLAttributes<HTMLDivElement>;

const TaskDeliverableDirectoryListItem: React.FC<Props> = ({
    taskDeliverablesTree,
    directoryPath,
    directoryName,
    onVisitDirectory,
    ...props
}) => {
    const permissions = useStore($permissions);

    const { removeDirectory } = useRemoveDirectory();
    // const { approveDirectory } = useApproveDirectory();

    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [isApprovingDirectory, setIsApprovingDirectory] = useState(false);
    const [isRemovingDirectory, setIsRemovingDirectory] = useState(false);

    const handleCloseMenu = () => {
        setIsMenuOpened(false);
    };

    const handleToggleMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();

        if (isMenuOpened) {
            // Close menu
            handleCloseMenu();
        } else {
            // Open menu
            setIsMenuOpened(true);
        }
    };

    const handleRemoveDirectory = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.stopPropagation();

        setIsRemovingDirectory(true);

        try {
            await removeDirectory({
                taskDeliverablesTree,
                directoryNameToRemove: directoryName,
                directoryPathToRemove: directoryPath,
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsRemovingDirectory(false);
        }
    };

    const handleRenameDirectory = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.stopPropagation();

        openModal<UpsertTaskDeliverableDirectoryModalProps>({
            ModalComponent: UpsertTaskDeliverableDirectoryModal,
            data: { directoryPath, directoryName },
        });
    };

    // const handleApproveDirectory = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    //     e.stopPropagation();

    //     setIsApprovingDirectory(true);

    //     try {
    //         await approveDirectory({
    //             taskDeliverablesTree,
    //             directoryNameToApprove: directoryName,
    //             directoryPathToApprove: directoryPath,
    //         });
    //     } catch (e) {
    //         // TODO: handle error
    //         console.error(e);
    //     } finally {
    //         setIsApprovingDirectory(false);
    //     }
    // };

    const renderRemoveDirectoryMenuItem = () => {
        return (
            <MenuItem
                disabled={isRemovingDirectory}
                intent={Intent.DANGER}
                text="Remove"
                icon="trash"
                onClick={handleRemoveDirectory}
            />
        );
    };

    const renderRenameDirectoryMenuItem = () => {
        return <MenuItem text="Rename" icon="edit" onClick={handleRenameDirectory} />;
    };

    // const renderApproveDirectoryMenuItem = () => {
    //     return (
    //         <MenuItem
    //             disabled={isApprovingDirectory}
    //             intent={Intent.SUCCESS}
    //             text="Approve"
    //             icon="confirm"
    //             onClick={handleApproveDirectory}
    //         />
    //     );
    // };

    const renderDirectoryMenu = () => {
        return (
            <Menu>
                {/* {renderApproveDirectoryMenuItem()} */}
                {renderRenameDirectoryMenuItem()}
                {renderRemoveDirectoryMenuItem()}
            </Menu>
        );
    };

    const renderToggleMenuButton = () => {
        // TODO: Disabled for now
        return null;

        const isMoreButtonLoading = isApprovingDirectory || isRemovingDirectory;

        return (
            <Popover isOpen={isMenuOpened} content={renderDirectoryMenu()} onClose={handleCloseMenu}>
                <Button minimal loading={isMoreButtonLoading} icon="more" onClick={handleToggleMenu} />
            </Popover>
        );
    };

    return (
        <Card elevation={Elevation.ONE} style={{ padding: '4px 8px' }} {...props}>
            <Flex align="center" justify="space-between">
                <Flex align="center">
                    <Icon className="mr-1" icon="folder-close" />
                    <Link onClick={onVisitDirectory}>{TextFormatUtils.truncate(directoryName, 40)}</Link>
                </Flex>

                {renderToggleMenuButton()}
            </Flex>
        </Card>
    );
};

export default TaskDeliverableDirectoryListItem;
