import { Card, Colors } from '@blueprintjs/core';
import styled, { css } from 'styled-components';
import { BlueprintVersion } from '@data/consts';

export const Wrapper = styled(Card)`
    padding: 0;
    border-radius: 2px;

    ${({ theme }) => css`
        background: ${Colors.DARK_GRAY1};
    `}
`;

export const AttachedFilesWrapper = styled.div`
    padding: 0px 16px 16px;
`;

export const EditorWrapper = styled.div<{
    $error?: boolean;
}>`
    border-radius: 2px;
    transition: background 60ms;

    & .rich-editor-action-divider {
        padding: 0 12px;

        & .${BlueprintVersion}-divider {
            margin-top: 0;
        }
    }

    & .rich-editor-controls {
        padding: 8px 16px 16px;
    }

    ${({ theme }) => {
        return css`
            background: ${Colors.DARK_GRAY1};

            & .public-DraftEditor-content,
            & .public-DraftEditorPlaceholder-root {
                color: ${Colors.WHITE};
                min-height: 70px;
                padding: 16px;
            }

            & .public-DraftEditorPlaceholder-root {
                color: ${Colors.GRAY2};
            }
        `;
    }}

    // error: true
    ${({ $error }) =>
        $error &&
        css`
            box-shadow: 0 0 0 1px ${Colors.RED2};
        `}
`;
