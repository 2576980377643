import { TaskDeliverableType } from 'dy-frontend-http-repository/lib/data/enums';
import { FileUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React from 'react';
import { $selectedTaskDeliverable } from '../../store/states';
import TaskDeliverableGoogleDriveViewer from './components/TaskDeliverableGoogleDriveViewer';
import TaskDeliverableImageViewer from './components/TaskDeliverableImageViewer';
import TaskDeliverablePdfViewer from './components/TaskDeliverablePdfViewer';
import TaskDeliverableTextViewer from './components/TaskDeliverableTextViewer';
import TaskDeliverableVideoViewer from './components/TaskDeliverableVideoViewer';
import { Wrapper } from './styled';

const TaskDeliverableViewerManager: React.FC = () => {
    const selectedTaskDeliverable = useStore($selectedTaskDeliverable);

    if (!selectedTaskDeliverable) {
        return null;
    }

    const renderFileViewer = () => {
        if (!selectedTaskDeliverable.file) {
            console.error('Task deliverable is of type file, but file is NOT provided');
            return null;
        }

        if (FileUtils.isImage(selectedTaskDeliverable.file.extension)) {
            return <TaskDeliverableImageViewer />;
        }

        if (FileUtils.isVideo(selectedTaskDeliverable.file.extension)) {
            return <TaskDeliverableVideoViewer />;
        }

        if (FileUtils.isPdf(selectedTaskDeliverable.file.extension)) {
            return <TaskDeliverablePdfViewer />;
        }

        // TODO: fallback to FilePreview + download button in order to view content
        return null;
    };

    const renderEmbedViewer = () => {
        return <TaskDeliverableGoogleDriveViewer />;
    };

    const renderTextViewer = () => {
        return <TaskDeliverableTextViewer />;
    };

    const renderViewer = () => {
        switch (selectedTaskDeliverable.type) {
            case TaskDeliverableType.FILE:
                return renderFileViewer();
            case TaskDeliverableType.EMBED:
                return renderEmbedViewer();
            case TaskDeliverableType.TEXT:
                return renderTextViewer();
            default:
                // No viewer for this type

                // TODO: fallback to FilePreview + download button in order to view content
                return null;
        }
    };

    return <Wrapper className="custom-thin-scroll">{renderViewer()}</Wrapper>;
};

export default TaskDeliverableViewerManager;
