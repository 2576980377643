import domain from './domain';
import { AuthRoleResource } from 'dy-frontend-http-repository/lib/modules/AuthRole/resources';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { AuthRoleRef } from 'dy-frontend-http-repository/lib/modules/AuthRole/refs';
import { UpdateAuthRoleInput } from 'dy-frontend-http-repository/lib/modules/AuthRole/inputs';

// Fetch auth role
export const fetchAuthRole = domain.createEffect<ID, AuthRoleResource>({
    name: 'fetchAuthRole',
    handler: async (id) => {
        return await repository.authRole().getById(id);
    },
});

// Update auth role
export const updateAuthRole = domain.createEffect<
    { id: ID, input: UpdateAuthRoleInput },
    AuthRoleRef
>({
    name: 'updateAuthRole',
    handler: async ({ id, input }) => {
        return await repository.authRole().update(id, input);
    },
});

// Enable auth role
export const enableAuthRole = domain.createEffect<ID, AuthRoleRef>({
    name: 'enableAuthRole',
    handler: async (id) => {
        return await repository.authRole().enable(id);
    },
});

// Disable auth role
export const disableAuthRole = domain.createEffect<ID, AuthRoleRef>({
    name: 'disableAuthRole',
    handler: async (id) => {
        return await repository.authRole().disable(id);
    },
});

// Archive auth role
export const archiveAuthRole = domain.createEffect<ID, AuthRoleRef>({
    name: 'archiveAuthRole',
    handler: async (id) => {
        return await repository.authRole().archive(id);
    },
});

// Restore auth role
export const restoreAuthRole = domain.createEffect<ID, AuthRoleRef>({
    name: 'restoreAuthRole',
    handler: async (id) => {
        return await repository.authRole().restore(id);
    },
});
