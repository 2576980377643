import { usePageTitle } from '@app/hooks';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { $task } from '../../store/states';
import TaskComplexityManager from './components/TaskComplexityManagement';
import TaskPriorityManagement from './components/TaskPriorityManagement';
import { $permissions } from '@containers/store/states';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
/*
    Show high priority tag (danger + minimal) before task title on task page
*/

const TaskManagement: React.FC = () => {
    const permissions = useStore($permissions);

    const { changeTitle } = usePageTitle('Request Management');

    const task = useStore($task);

    useEffect(() => {
        if (!task) {
            return;
        }

        changeTitle(`Request Management - ${task.title}`);
    }, [task?.title]);

    const renderPriorityManage = () => {
        // Ensure allowed
        if (!permissions.isRoot.task && !permissions.has(TaskPermission.PRIORITY_MANAGE)) {
            return null;
        }

        return (
            <TaskPriorityManagement className="mb-2" />
        );
    }

    const renderComplexityManage = () => {
        // Ensure allowed
        if (!permissions.isRoot.task && !permissions.has(TaskPermission.COMPLEXITY_MANAGE)) {
            return null;
        }

        return <TaskComplexityManager />;
    }

    return (
        <div>
            {renderPriorityManage()}
            {renderComplexityManage()}
        </div>
    );
};

export default TaskManagement;
