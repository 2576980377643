import { $userAuthRoles } from './states';
import { createApi } from 'effector';
import { AuthRoleListItemResource } from 'dy-frontend-http-repository/lib/modules/AuthRole/resources';
import { UserAuthRoleResource } from 'dy-frontend-http-repository/lib/modules/User/resources';

export const userAuthRolesApi = createApi($userAuthRoles, {
    add: (
        store,
        payload: {
            userId: ID;
            authRole: AuthRoleListItemResource;
        }
    ) => {
        if (!store) {
            // User auth roles are NOT fetched yet

            return null;
        }

        const newUserAuthRole: UserAuthRoleResource = {
            user_id: payload.userId,
            auth_role: {
                ...payload.authRole,
            },
        };

        return {
            ...store,
            items: [...store.items, newUserAuthRole],
        };
    },
    removeAuthRole: (store, payload: ID) => {
        if (!store) {
            // User auth roles are NOT fetched yet
            return store;
        }

        return {
            ...store,
            items: [...store.items.filter((x) => x.auth_role.id !== payload)],
        };
    },
});
