import React, { useEffect } from 'react';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Heading from '@components/Heading';
import Flex from '@components/Flex';
import { Button, Card, Intent, Spinner } from '@blueprintjs/core';
import { usePageTitle } from '@app/hooks';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';
import { $authRoles } from './store/states';
import { fetchAuthRoles } from '@pages/AuthRoles/store/effects';
import DevText from '@components/Text';
import { Endpoints } from '@data/consts';
import { resetAuthRoles } from './store/events';
import { openModal } from '@modals/store/events';
import CreateAuthRoleModal from '@pages/AuthRoles/modals/CreateAuthRoleModal';

const AuthRoles: React.FC = () => {
    usePageTitle('Auth Roles');

    const navigate = useNavigate();

    const authRoles = useStore($authRoles);

    useEffect(() => {
        // TODO: handle error
        fetchAuthRoles(undefined).catch(console.error);
    }, []);

    useEffect(() => {
        return () => {
            resetAuthRoles();
        };
    }, []);

    const renderCreateButton = () => {
        return (
            <Button
                onClick={() =>
                    openModal<{}>({
                        ModalComponent: CreateAuthRoleModal,
                        data: {},
                    })
                }
                intent={Intent.PRIMARY}
            >
                Create new
            </Button>
        );
    };

    const renderList = () => {
        // Not fetched
        if (authRoles === null) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // No content
        if (authRoles.items.length === 0) {
            return <DevText muted>No auth roles created</DevText>;
        }

        return authRoles.items.map((i) => (
            <Card className="mb-1">
                <Flex align="center" justify="space-between">
                    <DevText className="mb-1">
                        [{i.user_type}] {i.title}
                    </DevText>
                    <Button onClick={() => navigate(Endpoints.AUTH_ROLE.replace(':authRoleId', i.id))}>Edit</Button>
                </Flex>
            </Card>
        ));
    };

    return (
        <FixedWidthPageContainer>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h3">Auth roles</Heading>

                {renderCreateButton()}
            </Flex>
            {renderList()}
        </FixedWidthPageContainer>
    );
};

export default AuthRoles;
