import InputGroupSpinnerElement from '@app/components/InputGroupSpinnerElement';
import OccupancyRate from '@app/containers/components/OccupancyRate';
import TeamTag from '@app/containers/components/TeamTag';
import { useDebouncedState } from '@app/hooks';
import { Button, Card, Icon, InputGroup, Intent, Spinner, Tag } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import Overlay from '@components/Overlay';
import Pagination from '@components/Pagination';
import Table from '@components/Table';
import TableCell from '@components/TableCell';
import DevText from '@components/Text';
import { imageHashPreview } from '@data/consts';
import { ModalProps } from '@modals/types';
import { PickableTaskParticipantUserResource } from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { UserOccupancyResource } from 'dy-frontend-http-repository/lib/modules/UserOccupancy/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { taskApi } from '../../store/apis';
import { assignTaskParticipantUsers, fetchPickableTaskParticipantUsers, fetchUserOccupancy } from '../../store/effects';
import { resetTaskPickableParticipantUsers } from '../../store/events';
import { $task, $taskPickableParticipantUsers } from '../../store/states';
import { recordCountPerPage } from './consts';

type Props = ModalProps;

const AddTaskParticipantsModal: React.FC<Props> = ({ closeModal }) => {
    const task = useStore($task);
    const taskPickableParticipantUsers = useStore($taskPickableParticipantUsers);
    const isFetchingPickableTaskParticipantUsers = useStore(fetchPickableTaskParticipantUsers.pending);

    const [page, setPage] = useState(1);
    const [isAssigningParticipantUsers, setIsAssigningParticipantUsers] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<PickableTaskParticipantUserResource[]>([]);
    const [userOccupancyByUserId, setUserOccupancyByUserId] = useState<
        { [userId in ID]: UserOccupancyResource } | null
    >(null);
    const [query, setQuery] = useState('');
    const debouncedQuery = useDebouncedState<string>(query, 1000);

    useEffect(() => {
        if (!taskPickableParticipantUsers || !userOccupancyByUserId) {
            return;
        }

        if (page === 1) {
            handleLoadPage(page);
        } else {
            setPage(1);
        }
    }, [debouncedQuery]);

    useEffect(() => {
        handleLoadPage(page);
    }, [page]);

    useEffect(() => {
        return () => {
            resetTaskPickableParticipantUsers();
        };
    }, []);

    if (!task) {
        closeModal?.();
        return null;
    }

    const removeUserFromSelected = (user: PickableTaskParticipantUserResource) => {
        setSelectedUsers((selected) => selected.filter((x) => x.id !== user.id));
    };

    const handleLoadPage = async (newPage: number) => {
        const pageOffset = (newPage - 1) * recordCountPerPage;

        try {
            // Fetch task participant users
            const pickableTaskParticipantUsers = await fetchPickableTaskParticipantUsers({
                pagination: {
                    _pagination: { limit: recordCountPerPage, offset: pageOffset },
                },
                filter: {
                    task_id: task.id,
                    query: debouncedQuery,
                },
            });

            // Get user occupancy for fetched task participant users
            const isParticipantUsersPresent = pickableTaskParticipantUsers.items.length > 0;
            if (isParticipantUsersPresent) {
                let map: { [userId in ID]: UserOccupancyResource } = {};
                const userOccupancy = await fetchUserOccupancy({
                    user_id: pickableTaskParticipantUsers.items.map((user) => user.id),
                });

                for (let i = 0; i < userOccupancy.items.length; i++) {
                    const occupancy = userOccupancy.items[i];
                    map[occupancy.user_id] = occupancy;
                }

                setUserOccupancyByUserId(map);
            }
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const handleAssignTaskParticipantUsers = async () => {
        // Skip if selected users are empty for some reason
        if (!task || selectedUsers.length === 0) {
            closeModal?.();
            return;
        }

        setIsAssigningParticipantUsers(true);

        try {
            await assignTaskParticipantUsers({
                taskId: task.id,
                input: {
                    user_ids: selectedUsers.map((user) => user.id),
                },
            });

            taskApi.addParticipation({ input: selectedUsers });
            closeModal?.();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsAssigningParticipantUsers(false);
        }
    };

    const renderHeader = () => {
        return (
            <>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h3">Select users to add</Heading>

                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <InputGroup
                    large
                    leftIcon="search"
                    placeholder="Search for participants..."
                    value={query}
                    leftElement={
                        <InputGroupSpinnerElement
                            loading={isFetchingPickableTaskParticipantUsers && query.trim().length !== 0}
                        />
                    }
                    onChange={(e) => setQuery(e.target.value)}
                />
            </>
        );
    };

    const renderSelectedUsers = () => {
        if (selectedUsers.length === 0) {
            return null;
        }

        const renderAsTagList = () =>
            selectedUsers.map((user) => (
                <Tag
                    key={`selected-user-${user.id}`}
                    className="mr-1 mb-1"
                    onRemove={() => removeUserFromSelected(user)}
                >
                    {user.first_name} {user.last_name}
                </Tag>
            ));

        return <div className="mb-1">{renderAsTagList()}</div>;
    };

    const renderPickableUsersTable = () => {
        if (!taskPickableParticipantUsers || !userOccupancyByUserId) {
            // Fetching user to attach to task & user occupancy
            return <Spinner />;
        }

        if (taskPickableParticipantUsers.items.length === 0) {
            // No users to attach to task
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No users can be assigned to this task
                        </Heading>
                    }
                />
            );
        }

        // Header
        const renderTableHeader = () => {
            return (
                <thead>
                <tr>
                    <th>User</th>
                    <th>Occupancy</th>
                    <th>Team</th>
                    <th style={{ width: '20%' }}>Action</th>
                </tr>
                </thead>
            );
        };

        // Data table
        const renderTableBody = () => {
            const renderRow = (user: PickableTaskParticipantUserResource) => {
                // Returns flag is user is selected for assignment
                // TODO: Optimize by using map where ID is a key and value is resource, O(n) -> O(1)
                const isSelected = (userId: ID) => {
                    return selectedUsers.map((x) => x.id).includes(userId);
                };

                const addUserToSelected = (user: PickableTaskParticipantUserResource) => {
                    setSelectedUsers((selected) => [...selected, user]);
                };

                const renderActions = () => {
                    // User is not selected
                    if (!isSelected(user.id)) {
                        return (
                            <Button intent={Intent.PRIMARY} outlined icon="add" onClick={() => addUserToSelected(user)}>
                                Select
                            </Button>
                        );
                    }

                    return (
                        <Button
                            intent={Intent.DANGER}
                            outlined
                            icon="remove"
                            onClick={() => removeUserFromSelected(user)}
                        >
                            Remove
                        </Button>
                    );
                };

                const renderUserOccupancyInformation = () => {
                    const userOccupancy = userOccupancyByUserId[user.id];
                    if (!userOccupancy) {
                        return <DevText muted>No data</DevText>;
                    }

                    return <OccupancyRate score={userOccupancy.score} />;
                };

                const renderParticipantUserTeams = () => {
                    if (user.team_participation.length === 0) {
                        // Participant user is NOT part of any team
                        return <DevText muted>No team</DevText>;
                    }

                    return user.team_participation.map((teamParticipation, index) => (
                        <TeamTag
                            key={teamParticipation.team.id}
                            id={teamParticipation.team.id}
                            className={index === 0 ? '' : 'mt-1'}
                            name={teamParticipation.team.title}
                            color={teamParticipation.team.color}
                        />
                    ));
                };

                // Get avatar src
                let avatarSrc: string | null = null;
                if (user.image_hash) {
                    avatarSrc = imageHashPreview.userImage(user.image_hash, ImageHashPreviewSize.SM);
                }

                return (
                    <tr key={`pickable-user-${user.id}`}>
                        <TableCell verticalAlign="middle">
                            <Flex align="center">
                                <Avatar
                                    className="mr-1"
                                    width="42px"
                                    height="42px"
                                    alt={user.first_name}
                                    src={avatarSrc}
                                />

                                <Flex direction="column">
                                    <Flex align="center">
                                        <DevText>
                                            {user.first_name} {user.last_name}
                                        </DevText>
                                    </Flex>

                                    <DevText muted>
                                        {user.company_position.length > 0 ? user.company_position : 'Can be assigned'}
                                    </DevText>
                                </Flex>
                            </Flex>
                        </TableCell>
                        <TableCell verticalAlign="middle">{renderUserOccupancyInformation()}</TableCell>
                        <TableCell verticalAlign="middle">{renderParticipantUserTeams()}</TableCell>
                        <TableCell verticalAlign="middle">{renderActions()}</TableCell>
                    </tr>
                );
            };

            return <tbody>{taskPickableParticipantUsers?.items.map(renderRow)}</tbody>;
        };

        return (
            <Table striped loading={isFetchingPickableTaskParticipantUsers} className="mb-1">
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        );
    };

    const renderPagination = () => {
        if (!userOccupancyByUserId) {
            // User occupancy by user ID is not initialized yet
            return null;
        }

        // Skip if not loaded or no pagination is supported
        if (taskPickableParticipantUsers === null || taskPickableParticipantUsers.paginator === null) {
            return null;
        }

        // Only 1 page exists
        if (!taskPickableParticipantUsers.paginator.has_more && taskPickableParticipantUsers.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingPickableTaskParticipantUsers}
                    hasMore={taskPickableParticipantUsers.paginator.has_more}
                    className="mb-2"
                    page={page}
                    amountOfItemsOnPage={recordCountPerPage}
                    totalItems={taskPickableParticipantUsers.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '800px' }}>
                {/* Header */}
                <div className="mb-2">{renderHeader()}</div>

                {/* Selected users */}
                {renderSelectedUsers()}

                {/* Pickable users table */}
                {renderPickableUsersTable()}

                {/* Render pagination */}
                {renderPagination()}

                <Flex justify="flex-end">
                    <Button minimal disabled={isAssigningParticipantUsers} className="mr-1" onClick={closeModal}>
                        Cancel
                    </Button>

                    <Button
                        disabled={selectedUsers.length === 0}
                        loading={isAssigningParticipantUsers}
                        icon="add"
                        intent={selectedUsers.length !== 0 ? Intent.PRIMARY : Intent.NONE}
                        onClick={handleAssignTaskParticipantUsers}
                    >
                        {selectedUsers.length !== 0 ? 'Add selected' : 'Select users to add'}
                    </Button>
                </Flex>
            </Card>
        </Overlay>
    );
};

export default AddTaskParticipantsModal;
