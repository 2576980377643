import React, { useEffect, useState } from 'react';
import { usePageTitle } from '@app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { Button, Callout, Card, Classes, Intent, Spinner, TextArea } from '@blueprintjs/core';
import Grid from '@components/Grid';
import {
    archiveAuthRole,
    disableAuthRole,
    enableAuthRole,
    fetchAuthRole,
    restoreAuthRole,
    updateAuthRole,
} from './store/effects';
import { useStore } from 'effector-react';
import { $authRole } from './store/states';
import DevText from '@components/Text';
import { setPageBreadcrumbs } from '@containers/store/events';
import { Endpoints } from '@data/consts';
import { authRoleApi } from './store/apis';
import ConfirmationPopover from '@components/ConfirmationPopover';
import { resetAuthRole } from './store/events';

const AuthRole: React.FC = () => {
    // TODO: Set correct page title
    usePageTitle('Auth Role');

    const navigate = useNavigate();
    const { authRoleId } = useParams() as { authRoleId: ID };

    const authRole = useStore($authRole);
    const isUpdating = useStore(updateAuthRole.pending);
    const isEnabling = useStore(enableAuthRole.pending);
    const isDisabling = useStore(disableAuthRole.pending);
    const isArchiving = useStore(archiveAuthRole.pending);
    const isRestoring = useStore(restoreAuthRole.pending);

    const [permissions, setPermissions] = useState('');

    useEffect(() => {
        if (!authRole) {
            return;
        }

        // Breadcrumbs
        setPageBreadcrumbs([
            {
                text: 'Auth Roles',
                onClick: () => navigate(Endpoints.AUTH_ROLES),
            },
            { text: authRole.title },
        ]);

        // Init permissions
        setPermissions(authRole.permissions.join('\n'));
    }, [authRole?.id]);

    useEffect(() => {
        // TODO: Handle error
        fetchAuthRole(authRoleId).catch(console.error);
    }, [authRoleId]);

    useEffect(() => {
        return () => {
            resetAuthRole();
        };
    }, []);

    if (!authRole) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const isSystemRole = authRole.lookup_key !== null;

    const handleUpdatePermissions = () => {
        const normalized = permissions
            .replace(/[^\S\n\r]+/g, '')
            .split('\n')
            .filter((i) => i.length > 0)
            .map((i) => i.toLowerCase());

        const uniquePermissionList = normalized.filter((item, pos) => normalized.indexOf(item) === pos).sort();

        updateAuthRole({
            id: authRole.id,
            input: {
                permissions: uniquePermissionList,
                summary: authRole.summary,
                title: authRole.title,

                // TODO: Make editable for non-system roles
                is_sensitive: false,
            },
        })
            .then(() => {
                setPermissions(uniquePermissionList.join('\n'));
                authRoleApi.update({ permissions: uniquePermissionList });
            })
            .catch((e) => {
                // TODO: Handle error
                console.error(e);
            });
        return;
    };

    const handlePermissionsChange = (next: string) => {
        setPermissions(next);
    };

    const renderSystemAuthRoleCallout = () => {
        // Skip if not system
        if (!isSystemRole) {
            return null;
        }

        return (
            <Callout className={'mb-2'} intent={Intent.PRIMARY} title={`This is a system role: ${authRole.lookup_key}`}>
                <DevText running className="mb-1">
                    This role is managed by the platform automatically. You cannot disable or archive it, however you
                    can edit permission list, associated with it. While it is allowed to update system role permissions,
                    it is advisable to create separate role if you want to manage user-specific permissions
                </DevText>

                <DevText></DevText>
            </Callout>
        );
    };

    const renderArchivedCallout = () => {
        // Skip if not archived
        if (authRole.archived_at === null) {
            return null;
        }

        const renderRestoreButton = () => {
            // Skip if system
            if (isSystemRole) {
                return null;
            }

            return (
                <Button
                    className={'mt-2'}
                    intent={Intent.WARNING}
                    onClick={() => {
                        restoreAuthRole(authRole.id)
                            .then(() => authRoleApi.restore())
                            .catch(console.error);
                    }}
                    loading={isRestoring}
                    disabled={isUpdating || isArchiving || isEnabling || isDisabling}
                >
                    Restore auth role
                </Button>
            );
        };

        return (
            <Callout className={'mb-2'} intent={Intent.WARNING} title="Auth role is archived">
                <DevText>This auth role is archived. Archived roles have to effect on users they assigned to.</DevText>
                {renderRestoreButton()}
            </Callout>
        );
    };

    const renderArchiveButton = () => {
        // Skip if system
        if (isSystemRole) {
            return null;
        }

        // Ensure not archived
        if (authRole.archived_at !== null) {
            return null;
        }

        return (
            <ConfirmationPopover
                title="Archive auth role?"
                description="After archiving auth role, it will be also disabled"
                actions={[
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={() => {
                            archiveAuthRole(authRole.id)
                                .then(() => authRoleApi.archive())
                                .catch(console.error);
                        }}
                    >
                        Yes, archive
                    </Button>,
                ]}
            >
                <Button
                    minimal
                    className="ml-1"
                    icon="trash"
                    intent={Intent.DANGER}
                    loading={isArchiving}
                    disabled={isUpdating || isRestoring || isEnabling || isDisabling}
                >
                    Delete
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderEnableControlButton = () => {
        // Skip if system
        if (isSystemRole) {
            return null;
        }

        // Ensure not archived
        if (authRole.archived_at !== null) {
            return null;
        }

        const renderEnableButton = () => {
            return (
                <ConfirmationPopover
                    title="Archive auth role?"
                    description="After enabling, this role will become active"
                    actions={[
                        <Button
                            intent={Intent.PRIMARY}
                            className={Classes.POPOVER_DISMISS}
                            onClick={() => {
                                enableAuthRole(authRole.id)
                                    .then(() => authRoleApi.enable())
                                    .catch(console.error);
                            }}
                        >
                            Yes, enable
                        </Button>,
                    ]}
                >
                    <Button
                        minimal
                        className="ml-1"
                        icon="tick-circle"
                        intent={Intent.PRIMARY}
                        loading={isEnabling}
                        disabled={isUpdating || isRestoring || isArchiving || isDisabling}
                    >
                        Enable
                    </Button>
                </ConfirmationPopover>
            );
        };

        const renderDisableButton = () => {
            return (
                <ConfirmationPopover
                    title="Disable auth role?"
                    description="Disabled auth roles will not have any effect users it is assigned to"
                    actions={[
                        <Button
                            intent={Intent.WARNING}
                            className={Classes.POPOVER_DISMISS}
                            onClick={() => {
                                disableAuthRole(authRole.id)
                                    .then(() => authRoleApi.disable())
                                    .catch(console.error);
                            }}
                        >
                            Yes, disable
                        </Button>,
                    ]}
                >
                    <Button
                        minimal
                        className="ml-1"
                        icon="disable"
                        intent={Intent.WARNING}
                        loading={isDisabling}
                        disabled={isUpdating || isRestoring || isEnabling || isArchiving}
                    >
                        Disable
                    </Button>
                </ConfirmationPopover>
            );
        };

        return authRole.is_enabled ? renderDisableButton() : renderEnableButton();
    };

    return (
        <FixedWidthPageContainer>
            {renderArchivedCallout()}

            {renderSystemAuthRoleCallout()}

            <Flex className="mb-2" align="center" justify="space-between">
                <div>
                    <Heading type="h3">Role: {authRole.title}</Heading>
                </div>
                <div>
                    {renderEnableControlButton()}
                    {renderArchiveButton()}
                </div>
            </Flex>

            <Grid container>
                <Grid lg={3} xs={12}>
                    <Heading type="h4" className="mb-2">
                        Info
                    </Heading>
                    <Card>
                        <DevText className="mb-1">ID: {authRole.id}</DevText>
                        <DevText className="mb-1">User type: {authRole.user_type}</DevText>
                        <DevText className="mb-1">Enabled: {authRole.is_enabled ? 'Yes' : 'No'}</DevText>
                        <DevText className="mb-1">Summary: {authRole.summary}</DevText>
                    </Card>
                </Grid>
                <Grid lg={9} xs={12}>
                    <Flex className="mb-2" align="center" justify="space-between">
                        <Heading type="h4">Permissions</Heading>
                        <Button
                            loading={isUpdating}
                            disabled={isArchiving || isRestoring || isEnabling || isDisabling}
                            onClick={handleUpdatePermissions}
                        >
                            Save permissions
                        </Button>
                    </Flex>
                    <TextArea
                        fill
                        autoResize
                        readOnly={isSystemRole}
                        placeholder={'Type list of permissions here'}
                        value={permissions}
                        onChange={(e) => handlePermissionsChange(e.target.value)}
                    />
                </Grid>
            </Grid>
        </FixedWidthPageContainer>
    );
};

export default AuthRole;
