import moment from 'moment';
import { createApi } from 'effector';
import { $authRole } from './states';
import { AuthRoleResource } from 'dy-frontend-http-repository/lib/modules/AuthRole/resources';

export const authRoleApi = createApi($authRole, {
    update: (store, payload: Partial<AuthRoleResource>) => {
        if (!store) {
            return store;
        }

        return { ...store, ...payload };
    },

    enable: (store) => {
        if (!store) {
            return store;
        }

        return { ...store, is_enabled: true };
    },

    disable: (store) => {
        if (!store) {
            return store;
        }

        return { ...store, is_enabled: false };
    },

    archive: (store) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            archived_at: moment().utc().format(),
            is_enabled: false,
        };
    },

    restore: (store) => {
        if (!store) {
            return store;
        }

        return { ...store, archived_at: null };
    },
});
