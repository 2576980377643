import { UserResource } from 'dy-frontend-http-repository/lib/modules/Me/resources';
import {
    CreateTaskDeliverableCommentInput,
    UpdateTaskDeliverableCommentInput,
} from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/inputs';
import { TaskDeliverableCommentResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/resources';
import { createApi } from 'effector';
import moment from 'moment';
import { RootTaskDeliverableCommentsByIdMap } from '../types';
import PointMarkerByTaskDeliverableCommentIdMap from '../types/PointMarkerByTaskDeliverableCommentIdMap';
import { $pointMarkerByTaskDeliverableCommentIdMap, $rootTaskDeliverableCommentsByIdMap } from './states';

// API to manage task deliverable comments state locally
export const taskDeliverableCommentsApi = createApi($rootTaskDeliverableCommentsByIdMap, {
    initialize: (store, payload: { taskDeliverableComments: TaskDeliverableCommentResource[] }) => {
        // Rule 1: There are maximum of 2 levels for comments indentation: "root comment" and "reply comment to the root comment", even though server kind of allow 2 levels will be more then enough

        const rootTaskDeliverableCommentsByIdMap: RootTaskDeliverableCommentsByIdMap = {};

        if (payload.taskDeliverableComments.length === 0) {
            // There are NO comments created yet
            return rootTaskDeliverableCommentsByIdMap;
        }

        for (let i = 0; i < payload.taskDeliverableComments.length; i++) {
            const comment = payload.taskDeliverableComments[i];

            rootTaskDeliverableCommentsByIdMap[comment.id] = {
                ...comment,
            };
        }

        return rootTaskDeliverableCommentsByIdMap;
    },

    // Create task deliverable comment
    createTaskDeliverableComment: (
        rootTaskDeliverableCommentsByIdMap,
        payload: {
            id: ID;
            user: UserResource;
            input: CreateTaskDeliverableCommentInput;
        }
    ) => {
        // Task deliverable comments were NOT fetched yet OR rootTaskDeliverableCommentsByIdMap was not initialized yet
        if (!rootTaskDeliverableCommentsByIdMap) {
            return rootTaskDeliverableCommentsByIdMap;
        }

        // parentTaskDeliverableCommentId being NOT null means that reply comment for parentTaskDeliverableCommentId have to be created (reply comment)
        // parentTaskDeliverableCommentId being null means that comment should be created to root level(root comment)

        const momentDateNowFormatted = moment().utc().format();

        // Create comment
        const taskDeliverableComment: TaskDeliverableCommentResource = {
            id: payload.id,
            archived_at: null,
            resolved_at: null,
            created_at: momentDateNowFormatted,
            updated_at: momentDateNowFormatted,
            is_internal: payload.input.is_internal,
            content: payload.input.content,
            parent_task_deliverable_comment_id: payload.input.parent_task_deliverable_comment_id,
            children: [],
            marker_point: payload.input.marker_point ?? null,
            marker_point_area: payload.input.marker_point_area ?? null,
            marker_time: payload.input.marker_time ?? null,
            user: {
                ...payload.user,
            },
        };

        if (payload.input.parent_task_deliverable_comment_id !== null) {
            // Reply comment have to be created

            // Get parent comment
            const parentComment = rootTaskDeliverableCommentsByIdMap[payload.input.parent_task_deliverable_comment_id];

            if (!parentComment) {
                // This is happens whenever NOT valid value was passed for payload.input.parent_task_deliverable_comment_id

                // TODO: handle error
                console.error(
                    `Not valid value was passed for payload.input.parent_task_deliverable_comment_id = ${payload.input.parent_task_deliverable_comment_id}`
                );

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Parent comment was found

            // Copy parent comment
            const copiedParentComment: TaskDeliverableCommentResource = { ...parentComment };

            // Insert comment into children array (reply comments) of copiedParentComment
            copiedParentComment.children.push(taskDeliverableComment);

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [copiedParentComment.id]: copiedParentComment,
            };
        } else {
            // Root comment have to be created

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [taskDeliverableComment.id]: taskDeliverableComment,
            };
        }
    },

    // Update task deliverable comment
    updateTaskDeliverableComment: (
        rootTaskDeliverableCommentsByIdMap,
        payload: { id: ID; parentTaskDeliverableCommentId: ID | null; input: UpdateTaskDeliverableCommentInput }
    ) => {
        // Task deliverable comments were NOT fetched yet OR rootTaskDeliverableCommentsByIdMap was not initialized yet
        if (!rootTaskDeliverableCommentsByIdMap) {
            return rootTaskDeliverableCommentsByIdMap;
        }

        // payload.parentTaskDeliverableCommentId being NOT null means that reply comment for payload.parentTaskDeliverableCommentId have to be updated (reply comment)
        // payload.parentTaskDeliverableCommentId being null means that comment should be updated to root level(root comment)

        if (payload.parentTaskDeliverableCommentId !== null) {
            // Reply comment have to be updated

            // Get parent comment
            const parentComment = rootTaskDeliverableCommentsByIdMap[payload.parentTaskDeliverableCommentId];

            if (!parentComment) {
                // This is happens whenever NOT valid value was passed for payload.parentTaskDeliverableCommentId

                // TODO: handle error
                console.error(
                    `Not valid value was passed for payload.parentTaskDeliverableCommentId = ${payload.parentTaskDeliverableCommentId}`
                );

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Parent comment was found

            // Copy parent comment
            const copiedParentComment: TaskDeliverableCommentResource = { ...parentComment };

            // Get children (reply) comment
            const childCommentIndex = copiedParentComment.children.findIndex((comment) => comment.id === payload.id);

            if (childCommentIndex === -1) {
                // This is happens whenever NOT valid value was passed for payload.id

                // TODO: handle error
                console.error(`Not valid value was passed for payload.id = ${payload.id}`);

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Create updated version of child comment
            const childComment: TaskDeliverableCommentResource = {
                ...copiedParentComment.children[childCommentIndex],
                ...payload.input,
            };

            // Update child component in the parent children array
            copiedParentComment.children[childCommentIndex] = childComment;

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [copiedParentComment.id]: copiedParentComment,
            };
        } else {
            // Root comment have to be updated

            // Get root comment
            const rootComment = rootTaskDeliverableCommentsByIdMap[payload.id];

            if (!rootComment) {
                // This is happens whenever NOT valid value was passed for payload.id

                // TODO: handle error
                console.error(`Not valid value was passed for payload.id = ${payload.id}`);

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Root comment was found

            // Copy root comment
            const updatedRootComment: TaskDeliverableCommentResource = { ...rootComment, ...payload.input };

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [updatedRootComment.id]: updatedRootComment,
            };
        }
    },

    // Archive task deliverable comment
    archiveTaskDeliverableComment: (
        rootTaskDeliverableCommentsByIdMap,
        payload: { parentTaskDeliverableCommentId: ID | null; id: ID }
    ) => {
        // Task deliverable comments were NOT fetched yet OR rootTaskDeliverableCommentsByIdMap was not initialized yet
        if (!rootTaskDeliverableCommentsByIdMap) {
            return rootTaskDeliverableCommentsByIdMap;
        }

        // payload.parentTaskDeliverableCommentId being NOT null means that reply comment for payload.parentTaskDeliverableCommentId have to be updated (reply comment)
        // payload.parentTaskDeliverableCommentId being null means that comment should be updated to root level(root comment)

        const momentDateNowFormatted = moment().utc().format();

        if (payload.parentTaskDeliverableCommentId !== null) {
            // Reply comment have to be updated

            // Get parent comment
            const parentComment = rootTaskDeliverableCommentsByIdMap[payload.parentTaskDeliverableCommentId];

            if (!parentComment) {
                // This is happens whenever NOT valid value was passed for payload.parentTaskDeliverableCommentId

                // TODO: handle error
                console.error(
                    `Not valid value was passed for payload.parentTaskDeliverableCommentId = ${payload.parentTaskDeliverableCommentId}`
                );

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Parent comment was found

            // Copy parent comment
            const copiedParentComment: TaskDeliverableCommentResource = { ...parentComment };

            // Get children (reply) comment
            const childCommentIndex = copiedParentComment.children.findIndex((comment) => comment.id === payload.id);

            if (childCommentIndex === -1) {
                // This is happens whenever NOT valid value was passed for payload.id

                // TODO: handle error
                console.error(`Not valid value was passed for payload.id = ${payload.id}`);

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Create updated version of child comment
            const childComment: TaskDeliverableCommentResource = {
                ...copiedParentComment.children[childCommentIndex],
                archived_at: momentDateNowFormatted,
            };

            // Update child component in the parent children array
            copiedParentComment.children[childCommentIndex] = childComment;

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [copiedParentComment.id]: copiedParentComment,
            };
        } else {
            // Root comment have to be updated

            // Get root comment
            const rootComment = rootTaskDeliverableCommentsByIdMap[payload.id];

            if (!rootComment) {
                // This is happens whenever NOT valid value was passed for payload.id

                // TODO: handle error
                console.error(`Not valid value was passed for payload.id = ${payload.id}`);

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Root comment was found

            // Copy root comment
            const archivedRootComment: TaskDeliverableCommentResource = {
                ...rootComment,
                archived_at: momentDateNowFormatted,
            };

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [archivedRootComment.id]: archivedRootComment,
            };
        }
    },

    // RestoreTaskDeliverableCommentsById archived task deliverable comment
    restoreTaskDeliverableCommentsByIdTaskDeliverableComment: (
        rootTaskDeliverableCommentsByIdMap,
        payload: { parentTaskDeliverableCommentId: ID | null; id: ID }
    ) => {
        // Task deliverable comments were NOT fetched yet OR rootTaskDeliverableCommentsByIdMap was not initialized yet
        if (!rootTaskDeliverableCommentsByIdMap) {
            return rootTaskDeliverableCommentsByIdMap;
        }

        // payload.parentTaskDeliverableCommentId being NOT null means that reply comment for payload.parentTaskDeliverableCommentId have to be updated (reply comment)
        // payload.parentTaskDeliverableCommentId being null means that comment should be updated to root level(root comment)

        if (payload.parentTaskDeliverableCommentId !== null) {
            // Reply comment have to be updated

            // Get parent comment
            const parentComment = rootTaskDeliverableCommentsByIdMap[payload.parentTaskDeliverableCommentId];

            if (!parentComment) {
                // This is happens whenever NOT valid value was passed for payload.parentTaskDeliverableCommentId

                // TODO: handle error
                console.error(
                    `Not valid value was passed for payload.parentTaskDeliverableCommentId = ${payload.parentTaskDeliverableCommentId}`
                );

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Parent comment was found

            // Copy parent comment
            const copiedParentComment: TaskDeliverableCommentResource = { ...parentComment };

            // Get children (reply) comment
            const childCommentIndex = copiedParentComment.children.findIndex((comment) => comment.id === payload.id);

            if (childCommentIndex === -1) {
                // This is happens whenever NOT valid value was passed for payload.id

                // TODO: handle error
                console.error(`Not valid value was passed for payload.id = ${payload.id}`);

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Create updated version of child comment
            const childComment: TaskDeliverableCommentResource = {
                ...copiedParentComment.children[childCommentIndex],
                archived_at: null,
            };

            // Update child component in the parent children array
            copiedParentComment.children[childCommentIndex] = childComment;

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [copiedParentComment.id]: copiedParentComment,
            };
        } else {
            // Root comment have to be updated

            // Get root comment
            const rootComment = rootTaskDeliverableCommentsByIdMap[payload.id];

            if (!rootComment) {
                // This is happens whenever NOT valid value was passed for payload.id

                // TODO: handle error
                console.error(`Not valid value was passed for payload.id = ${payload.id}`);

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Root comment was found

            // Copy root comment
            const restoredRootComment: TaskDeliverableCommentResource = {
                ...rootComment,
                archived_at: null,
            };

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [restoredRootComment.id]: restoredRootComment,
            };
        }
    },

    // Mark task deliverables comment as resolved
    markCommentAsResolved: (
        rootTaskDeliverableCommentsByIdMap,
        payload: { parentTaskDeliverableCommentId: ID | null; id: ID }
    ) => {
        // Task deliverable comments were NOT fetched yet OR rootTaskDeliverableCommentsByIdMap was not initialized yet
        if (!rootTaskDeliverableCommentsByIdMap) {
            return rootTaskDeliverableCommentsByIdMap;
        }

        // payload.parentTaskDeliverableCommentId being NOT null means that reply comment for payload.parentTaskDeliverableCommentId have to be updated (reply comment)
        // payload.parentTaskDeliverableCommentId being null means that comment should be updated to root level(root comment)

        const momentDateNowFormatted = moment().utc().format();

        if (payload.parentTaskDeliverableCommentId !== null) {
            // Reply comment have to be updated

            // Get parent comment
            const parentComment = rootTaskDeliverableCommentsByIdMap[payload.parentTaskDeliverableCommentId];

            if (!parentComment) {
                // This is happens whenever NOT valid value was passed for payload.parentTaskDeliverableCommentId

                // TODO: handle error
                console.error(
                    `Not valid value was passed for payload.parentTaskDeliverableCommentId = ${payload.parentTaskDeliverableCommentId}`
                );

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Parent comment was found

            // Copy parent comment
            const copiedParentComment: TaskDeliverableCommentResource = { ...parentComment };

            // Get children (reply) comment
            const childCommentIndex = copiedParentComment.children.findIndex((comment) => comment.id === payload.id);

            if (childCommentIndex === -1) {
                // This is happens whenever NOT valid value was passed for payload.id

                // TODO: handle error
                console.error(`Not valid value was passed for payload.id = ${payload.id}`);

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Create updated version of child comment
            const childComment: TaskDeliverableCommentResource = {
                ...copiedParentComment.children[childCommentIndex],
                resolved_at: momentDateNowFormatted,
            };

            // Update child component in the parent children array
            copiedParentComment.children[childCommentIndex] = childComment;

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [copiedParentComment.id]: copiedParentComment,
            };
        } else {
            // Root comment have to be updated

            // Get root comment
            const rootComment = rootTaskDeliverableCommentsByIdMap[payload.id];

            if (!rootComment) {
                // This is happens whenever NOT valid value was passed for payload.id

                // TODO: handle error
                console.error(`Not valid value was passed for payload.id = ${payload.id}`);

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Root comment was found

            // Copy root comment
            const resolvedRootComment: TaskDeliverableCommentResource = {
                ...rootComment,
                resolved_at: momentDateNowFormatted,
            };

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [resolvedRootComment.id]: resolvedRootComment,
            };
        }
    },

    // Remove comment resolved status
    removeCommentResolvedStatus: (
        rootTaskDeliverableCommentsByIdMap,
        payload: { parentTaskDeliverableCommentId: ID | null; id: ID }
    ) => {
        // Task deliverable comments were NOT fetched yet OR rootTaskDeliverableCommentsByIdMap was not initialized yet
        if (!rootTaskDeliverableCommentsByIdMap) {
            return rootTaskDeliverableCommentsByIdMap;
        }

        // payload.parentTaskDeliverableCommentId being NOT null means that reply comment for payload.parentTaskDeliverableCommentId have to be updated (reply comment)
        // payload.parentTaskDeliverableCommentId being null means that comment should be updated to root level(root comment)

        const momentDateNowFormatted = moment().utc().format();

        if (payload.parentTaskDeliverableCommentId !== null) {
            // Reply comment have to be updated

            // Get parent comment
            const parentComment = rootTaskDeliverableCommentsByIdMap[payload.parentTaskDeliverableCommentId];

            if (!parentComment) {
                // This is happens whenever NOT valid value was passed for payload.parentTaskDeliverableCommentId

                // TODO: handle error
                console.error(
                    `Not valid value was passed for payload.parentTaskDeliverableCommentId = ${payload.parentTaskDeliverableCommentId}`
                );

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Parent comment was found

            // Copy parent comment
            const copiedParentComment: TaskDeliverableCommentResource = { ...parentComment };

            // Get children (reply) comment
            const childCommentIndex = copiedParentComment.children.findIndex((comment) => comment.id === payload.id);

            if (childCommentIndex === -1) {
                // This is happens whenever NOT valid value was passed for payload.id

                // TODO: handle error
                console.error(`Not valid value was passed for payload.id = ${payload.id}`);

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Create updated version of child comment
            const childComment: TaskDeliverableCommentResource = {
                ...copiedParentComment.children[childCommentIndex],
                resolved_at: null,
            };

            // Update child component in the parent children array
            copiedParentComment.children[childCommentIndex] = childComment;

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [copiedParentComment.id]: copiedParentComment,
            };
        } else {
            // Root comment have to be updated

            // Get root comment
            const rootComment = rootTaskDeliverableCommentsByIdMap[payload.id];

            if (!rootComment) {
                // This is happens whenever NOT valid value was passed for payload.id

                // TODO: handle error
                console.error(`Not valid value was passed for payload.id = ${payload.id}`);

                return rootTaskDeliverableCommentsByIdMap;
            }

            // Root comment was found

            // Copy root comment
            const rootCommentWithRemovedResolvedStatus: TaskDeliverableCommentResource = {
                ...rootComment,
                resolved_at: null,
            };

            return {
                ...rootTaskDeliverableCommentsByIdMap,
                [rootCommentWithRemovedResolvedStatus.id]: rootCommentWithRemovedResolvedStatus,
            };
        }
    },
});

// API to manage task deliverable comment markers state locally
export const pointMarkerByTaskDeliverableCommentIdMapApi = createApi($pointMarkerByTaskDeliverableCommentIdMap, {
    initialize: (store, payload: { taskDeliverableComments: TaskDeliverableCommentResource[] }) => {
        const pointMarkerByTaskDeliverableId: PointMarkerByTaskDeliverableCommentIdMap = {};

        if (payload.taskDeliverableComments.length === 0) {
            // There are NO comments created yet
            return pointMarkerByTaskDeliverableId;
        }

        const latestTaskDeliverableComments = payload.taskDeliverableComments.sort((a, b) =>
            moment.utc(a.created_at).diff(moment.utc(b.created_at))
        );

        for (let i = 0, j = 1; i < latestTaskDeliverableComments.length; i++) {
            const comment = latestTaskDeliverableComments[i];

            if (comment.marker_point) {
                pointMarkerByTaskDeliverableId[comment.id] = {
                    index: j++,
                    marker: comment.marker_point,
                };
            }
        }

        return pointMarkerByTaskDeliverableId;
    },

    add: (store, payload: { taskDeliverableId: ID; markerPoint: TaskDeliverableCommentResource['marker_point'] }) => {
        if (!payload.markerPoint) {
            return store;
        }

        // Set last marker index
        const pointMarkers = Object.values(store);
        let lastMarkerIndex = 0;
        if (pointMarkers.length > 0) {
            lastMarkerIndex = pointMarkers[pointMarkers.length - 1].index;
        }

        return {
            ...store,
            [payload.taskDeliverableId]: {
                index: lastMarkerIndex + 1,
                marker: payload.markerPoint,
            },
        };
    },
});
