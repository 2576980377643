import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Callout, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import ReopenTaskModal, { ReopenTaskModalProps } from '@pages/Task/modals/ReopenTaskModal';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const Delivered: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const me = useStore($authorizedUser);
    const task = useStore($task);

    if (!me) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    // TODO: Render helper actions

    const renderReopenTaskFlowButton = () => {
        if (!permissions.isRoot.task && !permissions.has(TaskPermission.REOPEN)) {
            return null;
        }

        return (
            <Button
                minimal
                onClick={
                    () => openModal<ReopenTaskModalProps>({
                        ModalComponent: ReopenTaskModal, data: {
                            taskId: task.id,
                            ownerUserId: task.user.id,
                        },
                    })
                }
            >Reopen request</Button>
        );
    };

    return (
        <Callout intent={Intent.SUCCESS} icon="tick-circle">
            <Flex align="center" justify="space-between" className="mb-1">
                <Flex align="center" className="mb-1">
                    <Heading type="h4" className="mb-1">Delivered</Heading>
                </Flex>
                <Flex>
                    <div>{renderReopenTaskFlowButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>
                Amazing! We've successfully completed this request and therefore there's no actions
                necessary anymore.
            </DevText>
        </Callout>
    );
};

export default Delivered;
