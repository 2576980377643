import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $client } from '../../store/states';
import { usePageTitle } from '@app/hooks';
import UserAuthRoles from '@containers/components/UserAuthRoles';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';

const ClientAuthRoles: React.FC = () => {
    const { changeTitle } = usePageTitle('Auth Roles');

    const client = useStore($client);

    useEffect(() => {
        if (!client) {
            return;
        }

        changeTitle(`Auth Roles - ${client.first_name} ${client.last_name}`);
    }, [client?.first_name, client?.last_name]);

    if (!client) {
        return null;
    }

    return (
        <UserAuthRoles
            userId={client.id}
            userType={UserType.CLIENT}
        />
    );
};

export default ClientAuthRoles;
