import React, { HTMLAttributes, useState } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { useTaskStateTransition } from '@pages/Task/hooks';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const InProgress: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const isTransitionAllowed = permissions.isRoot.task || permissions.has(TaskPermission.STATE_TRANSITION);

    const { handleTransitionTaskState } = useTaskStateTransition();

    const authorizedUser = useStore($authorizedUser);
    const task = useStore($task);

    const [transitioningTo, setTransitionTo] = useState<TaskState | null>(null);

    if (!authorizedUser) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    const handleTransitionToState = async (state: TaskState) => {
        try {
            setTransitionTo(state);
            const res = await handleTransitionTaskState(task.id, state);
        } catch (e) {
            // TODO: handle error
        } finally {
            setTransitionTo(null);
        }
    };

    // TODO: Render helper actions

    const renderInternalQaTransitionButton = () => {
        const isAllowed = permissions.isRoot.task || (isTransitionAllowed && permissions.has(TaskPermission.STATE_TRANSITION_INTERNAL_QA));
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                intent={Intent.PRIMARY}
                icon="geosearch"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.INTERNAL_QA}
                onClick={() => handleTransitionToState(TaskState.INTERNAL_QA)}
            >Ready for QA</Button>
        );
    };

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Heading type="h4" className="mb-1">Processing</Heading>
                <Flex>
                    <div>{renderInternalQaTransitionButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>There's currently someone working on this request.</DevText>
            <DevText muted>
                To deliver assets, please make sure that you upload them to the deliverables for review,
                before presenting them to the client.
            </DevText>
            <DevText muted>When done, pass request to the QA for the internal review</DevText>
        </Card>
    );
};

export default InProgress;
