import { createApi } from 'effector';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { TaskDeliverablesParsedInformation } from '../types';
import { TaskDeliverablesTreeUtils } from '../valueObjects';
import { $taskDeliverablesTree } from './states';
import moment from 'moment';

// API to manage task deliverable comments state locally
export const taskDeliverablesTreeApi = createApi($taskDeliverablesTree, {
    insert: (store, payload: { taskDeliverables: TaskDeliverableResource[] }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (payload.taskDeliverables.length === 0) {
            // No task deliverables
            return taskDeliverablesInformation;
        }

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        for (let i = 0; i < payload.taskDeliverables.length; i++) {
            const deliverable = { ...payload.taskDeliverables[i] };
            const deliverablePath = deliverable.path;

            // Deliverable insertion
            taskDeliverablesInformation.deliverables = TaskDeliverablesTreeUtils.insertTaskDeliverable({
                deliverable,
                deliverablePath: deliverablePath,
                deliverablesMap: taskDeliverablesInformation.deliverables,
                skipInsertion: deliverable.archived_at !== null,
            });

            // Directory insertion
            if (deliverablePath !== '/' && deliverablePath.length > 1) {
                // Path is NOT "/" and there are characters exists after slash "/directory/mycooldeliverable"

                // Insert directory
                taskDeliverablesInformation.directories = TaskDeliverablesTreeUtils.insertDirectory({
                    directoryPath: deliverablePath,
                    directoriesMap: taskDeliverablesInformation.directories,
                    skipInsertion: deliverable.archived_at !== null,
                });
            }
        }

        return taskDeliverablesInformation;
    },

    createDirectory: (store, payload: { directoryPath: string; directoryName: string }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        // Get full directory path with name
        const directoryPath = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: payload.directoryPath,
            directoryName: payload.directoryName,
        });

        // Insert directory
        taskDeliverablesInformation.directories = TaskDeliverablesTreeUtils.insertDirectory({
            directoryPath: directoryPath,
            directoriesMap: taskDeliverablesInformation.directories,
        });

        return taskDeliverablesInformation;
    },

    renameDirectory: (
        store,
        payload: { commonDirectoryPath: string; oldDirectoryName: string; newDirectoryName: string },
    ) => {
        if (!store) {
            return store;
        }

        // Check which task deliverable paths start with oldDirectoryPath/oldDirectoryName and rename it to oldDirectoryPath/newDirectoryName
        const deliverables = { ...store.deliverables };
        const directories = { ...store.directories };

        // Get old path with directory name
        const oldPathWithDirectoryName = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: payload.commonDirectoryPath,
            directoryName: payload.oldDirectoryName,
        });

        // Get new path with directory name
        const newPathWithDirectoryName = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: payload.commonDirectoryPath,
            directoryName: payload.newDirectoryName,
        });

        // Update deliverables object
        for (const deliverablePath in deliverables) {
            try {
                const renamedPath = TaskDeliverablesTreeUtils.getRenamedPath({
                    currentPath: deliverablePath,
                    oldPath: oldPathWithDirectoryName,
                    newPath: newPathWithDirectoryName,
                });

                if (renamedPath) {
                    // Current path should be renamed

                    // Update path field for deliverables
                    const deliverablesMapCopy = { ...deliverables[deliverablePath] };
                    for (const deliverableId in deliverablesMapCopy) {
                        deliverablesMapCopy[deliverableId].path = renamedPath;
                    }

                    // Update deliverables object
                    deliverables[renamedPath] = deliverablesMapCopy;
                    delete deliverables[deliverablePath];
                }
            } catch (e) {
                // TODO: handle error
                console.error(e);
            }
        }

        // Update directories object
        for (const directoryPath in directories) {
            if (directoryPath === payload.commonDirectoryPath) {
                // Directory path found, need to rename directory

                // Delete old directory name
                delete directories[directoryPath][payload.oldDirectoryName];

                // Add new directory name
                directories[directoryPath][payload.newDirectoryName] = true;

                continue;
            }

            try {
                const renamedPath = TaskDeliverablesTreeUtils.getRenamedPath({
                    currentPath: directoryPath,
                    oldPath: oldPathWithDirectoryName,
                    newPath: newPathWithDirectoryName,
                });

                if (renamedPath) {
                    // Copy directory name map from the old directory path to the new directory path
                    directories[renamedPath] = { ...directories[directoryPath] };

                    // Delete old directory path map
                    delete directories[directoryPath];
                }
            } catch (e) {
                // TODO: handle error
                console.error(e);
            }
        }

        return {
            deliverables,
            directories,
        };
    },

    removeTaskDeliverable: (store, payload: { taskDeliverableId: ID; path: string }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        const taskDeliverables = taskDeliverablesInformation.deliverables[payload.path];
        if (!taskDeliverables) {
            // Task deliverables do NOT exist for this path
            return;
        }

        const foundTaskDeliverable = taskDeliverables[payload.taskDeliverableId];
        if (!foundTaskDeliverable) {
            // Task deliverable is NOT found
            return;
        }

        delete taskDeliverables[payload.taskDeliverableId];

        return taskDeliverablesInformation;
    },

    removeDirectory: (store, payload: { directoryPath: string; directoryName: string }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        for (const path in taskDeliverablesInformation.directories) {
            taskDeliverablesInformation.directories = TaskDeliverablesTreeUtils.removeDirectory({
                path,
                directoriesMap: taskDeliverablesInformation.directories,
                directoryName: payload.directoryName,
                directoryPath: payload.directoryPath,
            });
        }

        return taskDeliverablesInformation;
    },

    approveTaskDeliverable: (store, payload: { taskDeliverableId: ID; path: string }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        const taskDeliverables = taskDeliverablesInformation.deliverables[payload.path];
        if (!taskDeliverables) {
            // Task deliverables do NOT exist for this path
            return;
        }

        const foundTaskDeliverable = taskDeliverables[payload.taskDeliverableId];
        if (!foundTaskDeliverable) {
            // Task deliverable is NOT found
            return;
        }

        const momentNowUtc = moment().utc().format();
        foundTaskDeliverable.approved_at = momentNowUtc;

        return taskDeliverablesInformation;
    },

    approveDirectory: (store, payload: { directoryPath: string; directoryName: string }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        const fullDirectoryPathWithNameToRemove = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: payload.directoryPath,
            directoryName: payload.directoryName,
        });

        for (const deliverablePath in taskDeliverablesInformation.deliverables) {
            const shouldDeliverablesBeApproved = TaskDeliverablesTreeUtils.isSubPathPartOfPath({
                path: deliverablePath,
                subPath: fullDirectoryPathWithNameToRemove,
            });

            if (shouldDeliverablesBeApproved) {
                // Deliverables should be removed

                for (const deliverableId in taskDeliverablesInformation.deliverables[deliverablePath]) {
                    const deliverable = taskDeliverablesInformation.deliverables[deliverablePath][deliverableId];
                    if (deliverable.approved_at === null) {
                        const momentNowUtc = moment().utc().format();
                        taskDeliverablesInformation.deliverables[deliverablePath][deliverableId].approved_at =
                            momentNowUtc;
                    }
                }
            }
        }

        return taskDeliverablesInformation;
    },
});
