import React from 'react';
import { cardTypeIcons } from '@data/consts';
import { CardType } from '@data/enums';
import Image, { Props as ImageProps } from '@components/Image';

export interface CardPreviewProps {
    type: CardType | string;
}

export type Props = CardPreviewProps & Omit<ImageProps, 'src'>;

const CardPreview: React.FC<Props> = ({ type, ...props }) => {
    const cardIcon = cardTypeIcons[type];
    if (!cardIcon) {
        return <Image src={cardIcon} {...props} />;
    }

    return <Image src={cardIcon} {...props} />;
};

export default CardPreview;
